import { useAuth, type User } from '@lib/hooks'
import { type FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { MTNtracking, ConstructPartnerImageUrl, type MTNPartnerData } from '@shamaazi/mytennights'
import { isWidget } from '@lib/services'
import { Navigation } from '~/components'
import defaultLogoWhite from './logo-white.svg'
import defaultLogo from './logo.svg'
import WhiteLogo from './logo-all-white.svg'
import { Button } from '@lib/components'
import { HeaderTestIds } from '@lib/testing'

const useScrolled = (): boolean => {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const onScroll = (): void => {
      if (window.pageYOffset > 0) {
        setScrolled(true)
        return
      }
      setScrolled(false)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  return scrolled
}

export const Header: FC<{ transparent?: boolean, showProfileLink?: boolean, isEverGive?: boolean, partnerData?: MTNPartnerData }> = ({ transparent = false, showProfileLink = false, isEverGive = false, partnerData }) => {
  const scrolled = useScrolled()
  const [active, setActive] = useState(false)
  const { user, logout } = useAuth()
  let logoLight = defaultLogo
  let logoDark = defaultLogoWhite
  let landingUrl = '/'
  enum AuthButtonLabels {
    Account = 'ACCOUNT',
    LogOut = 'LOGOUT',
    LogIn = 'LOGIN'
  }

  const pos = transparent ? 'fixed' : 'sticky top-0'
  const inverted = transparent && !scrolled
  if (scrolled) {
    isEverGive = false
  }
  const cn = inverted ? 'bg-transparent' : 'bg-white border-b-1 border-mtn-gray-150'
  const links = inverted ? 'text-white hover:text-mtn-blue-200' : 'text-mtn-blue-800 hover:text-mtn-blue'
  const mobileLink = inverted ? 'border-mtn-blue-800' : 'border-mtn-gray-300'

  if (MTNtracking.getPartner().partner) {
    logoLight = partnerData?.partnerLogoLight ? ConstructPartnerImageUrl(partnerData.partnerLogoLight, partnerData?.name ?? '') : defaultLogo
    logoDark = partnerData?.partnerLogoDark ? ConstructPartnerImageUrl(partnerData.partnerLogoDark, partnerData?.name ?? '') : defaultLogoWhite
    landingUrl = `/partner/${MTNtracking.getPartner().partner}`
  }

  function onLogoutClick (): void {
    MTNtracking.logOut(user!.email)
    setActive(false)
    logout() // eslint-disable-line @typescript-eslint/no-floating-promises
  }

  const handleMTNTracking = (user: User | null): void => {
    MTNtracking.myProfile(user?.email)
  }

  return <>
    <header className={`z-50 px-6 sm:px-20 items-center py-4 w-full font-medium flex justify-between transition-colors duration-500 ease-in-out ${cn} ${pos}`}>
      {!isWidget &&
        <Link to={landingUrl}
          onClick={() => MTNtracking.home(user?.email)}>
          <img className="py-3.5 md:w-56 w-48" alt="MyTenNights" src={isEverGive ? WhiteLogo : (inverted ? logoDark : logoLight)} />
        </Link>}
      {isWidget && <img className="py-3.5 w-56" alt="MyTenNights" src={isEverGive ? WhiteLogo : (inverted ? logoDark : logoLight)} />}
      {!isWidget && showProfileLink && <>
        <nav className="md:block">
          <div className='flex gap-5'>
            <div className={`hidden md:block md:p-2 cursor-pointer ${scrolled ? 'border-black' : 'border-white'}`}>
              <div className='text-white'>
                {user !== null &&
                  <div>
                    <Link
                      data-test-id={HeaderTestIds.myProfile}
                      to={Navigation.schedules}
                      className={`${links} transition-colors duration-500 mr-5`}
                    >
                      {AuthButtonLabels.Account}
                    </Link>

                    <Link
                      data-test-id={HeaderTestIds.logout}
                      onClick={() => onLogoutClick()}
                      className={`${links} transition-colors duration-500`}
                      to={landingUrl}
                    >
                      {AuthButtonLabels.LogOut}
                    </Link>
                  </div>
                }
              </div>
            </div>
            <div className={`md:block md:p-2 cursor-pointer ${scrolled ? 'border-black' : 'border-white'}`}>
              <div className='text-white'>
                {
                  user === null &&
                  <Link
                    data-test-id={HeaderTestIds.myProfile}
                    onClick={() => handleMTNTracking(user)}
                    className={`${links} transition-colors duration-500`}
                    to={Navigation.schedules}
                  >
                    {AuthButtonLabels.LogIn}
                  </Link>
                }
              </div>
            </div>
          </div>
          {/* {isCampaignOpen() && <Link className="ml-10" to={{ hash: '#donate', pathname: '/' }}>
            <Button variant={inverted ? 'mtn-donate' : 'mtn-donate-inverted'} onClick={() => onDonateNowClick(ButtonLocation.TopBar, user?.email ?? '')}> DONATE </Button>
          </Link>} */}
        </nav>
        {
          user !== null &&
          <div className={`md:hidden absolute right-5 tham tham-w-6 mtn-grey-800 ${active ? 'tham-active' : ''}`} onClick={() => setActive(!active)}>
            <div className="tham-box">
              <div className={`tham-inner ${inverted ? 'bg-white' : 'bg-mtn-blue-800'} `} />
            </div>
          </div>
        }
      </>}
    </header>
    <nav
      className={`fixed min-h-screen md:w-72 w-full z-40 font-medium transform transition-all duration-500 left-full ${active ? '-translate-x-full' : ''} ${inverted ? 'bg-mtn-blue-900' : 'bg-white'}`}>
      <div className={`${transparent ? 'mt-20' : ''} flex flex-col px-8`}>
        {/* {isCampaignOpen() && <Link
          onClick={() => setActive(false)}
          className={`${links} ${mobileLink} py-14 border-b w-full text-center transition-colors duration-500`}
          to={{ hash: '#donate', pathname: '/' }}>
          <Button variant="mtn-primary" className="border-8" onClick={() => onDonateNowClick(ButtonLocation.TopBar, user?.email ?? '')}> DONATE </Button>
        </Link>} */}
        <Link
          data-test-id={HeaderTestIds.myProfile}
          onClick={() => {
            MTNtracking.myProfile(user?.email)
            setActive(false)
          }}
          className={`${links} ${mobileLink} py-14 border-b w-full text-center transition-colors duration-500`}
          to="/account/schedules">
          <Button variant="mtn-primary" className="border-8">{AuthButtonLabels.Account}</Button>
        </Link>
        {user !== null && <Link
          data-test-id={HeaderTestIds.logout}
          onClick={() => onLogoutClick()}
          className={`${links} ${mobileLink} py-14 border-b w-full text-center transition-colors duration-500`}
          to={landingUrl}
        >
          {AuthButtonLabels.LogOut}
        </Link>}
      </div>
    </nav>
  </>
}
