import { Spinner } from '@lib/components'
import { type FC } from 'react'
import { useQuery } from 'react-query'
import { ActiveCampaign, type CharityCampaign, loadHomepageCharityCampaigns, useCheckoutPlan, MTNtracking, isCampaignOpen } from '@shamaazi/mytennights'

import { CheckoutProgress, Navigation, Pages } from '~/components'
import { useNavigate } from 'react-router-dom'

import { Header } from '~/components/header'
import { HomePageCharities } from '~/components/HomePageCharities'
import { ShareLinkCharities } from '~/components/ShareLinkCharities'

export const SelectCharity: FC<{ nextPage: string }> = ({ nextPage }) => {
  // get partners value
  const partner = MTNtracking.getPartner().partner ?? ''
  const { isLoading, error, data: charities } = useQuery('charities', async () => loadHomepageCharityCampaigns(ActiveCampaign.myTenNights))
  const { setCharity, plan } = useCheckoutPlan()
  const navigate = useNavigate()
  const onSelect = (charity: CharityCampaign): void => {
    setCharity(charity)
    const partner = MTNtracking.getPartner().partner
    navigate(Navigation.prefixPartnerApplicableRoute(nextPage, partner))
  }

  return (
    <div>
      <Header />
      <CheckoutProgress step={Pages.Select} />
      {isCampaignOpen() &&
          <div className="flex justify-center bg-mtn-gray-900">
            {error !== null && <p className="font-medium text-center text-mtn-red">Sorry, we were unable to load any charities!</p>}
            {isLoading && <Spinner className="text-mtn-blue" />}
            {error === null && !isLoading && charities && !plan.chainCode && <HomePageCharities onSelect={onSelect} charities={charities} partner={partner}/>}
            {error === null && !isLoading && charities && plan.chainCode && plan.chainCodeCharity.charity_id && <ShareLinkCharities onSelect={onSelect} charities={charities} partner={partner}/>}
          </div>
      }
    </div>)
}
