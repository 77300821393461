import { type FC, type ReactElement, useEffect, useState } from 'react'
import ArrowUp from '~/img/arrow-up-white.svg'
import { getCausesTotal, getSplitBreakdown, getTipAmount, getTransactionFeeToolTipText, isMobileScreen, MessageBox, mtnSplitTexts, Tip, tipValues, useCheckoutPlan, Variant } from '@shamaazi/mytennights'
import { displayCurrency } from '@lib/services'
import { MytennightsTestId } from '@lib/testing'
import { Button, Modal } from '@lib/components'
import { DateTime } from 'luxon'
import { Split } from '~/pages/home/Split'
import { getTotalFees } from '~/service/fees'

interface CheckoutSummaryProps {
  renderActionButton: () => ReactElement
  showDonationCauses?: boolean
  showDonationBreakdown?: boolean
  preSubmitError?: string
  postSubmitError?: string
}

// Appears as a sticky bottom drawer on mobile or a card on desktop.
export const CheckoutSummary: FC<CheckoutSummaryProps> = ({ renderActionButton, showDonationCauses, showDonationBreakdown, preSubmitError, postSubmitError }) => {
  const [expanded, setExpanded] = useState(false)
  const [openBreakdown, setOpenBreakdown] = useState(false)
  const [openSplit, setOpenSplit] = useState(false)

  const { plan, setPlan } = useCheckoutPlan()
  const { payTip, payFees } = plan.paymentDetails

  const tipAmount = payTip ? getTipAmount(plan.causes, plan.paymentDetails.tipPercent, plan.paymentDetails.tipValue / 100) : 0
  const causesTotalAmount = Object.values(plan.causes).reduce((acc, v) => acc + v, 0)
  const totalFeesAmount = causesTotalAmount ? getTotalFees(plan, tipAmount) : 0
  const donationTotal = tipAmount + totalFeesAmount + causesTotalAmount
  const toggleFees = (): void => setPlan({ ...plan, paymentDetails: { ...plan.paymentDetails, payFees: !plan.paymentDetails.payFees } })
  const toggleTip = (): void => {
    const newTipValue = payTip ? 0 : getTipAmount(plan.causes, plan.paymentDetails.tipPercent, plan.paymentDetails.tipValue / 100)
    setPlan({ ...plan, paymentDetails: { ...plan.paymentDetails, payTip: !plan.paymentDetails.payTip, tipValue: newTipValue } })
  }

  const [isMobile, setIsMobile] = useState(isMobileScreen())
  useEffect(() => {
    window.addEventListener('resize', () => setIsMobile(isMobileScreen()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  const renderCloseButton = (handleClose: () => void): ReactElement => {
    return <div className="mt-6 mb-10 flex justify-center">
    <Button
      data-test-id={MytennightsTestId.continueButton}
      variant="mtn-donate-now"
      className='bg-mtn-blue-250 border-2 border-white text-2xl py-3 w-72 uppercase font-bold '
      onClick={() => {
        handleClose()
      }}
    >
      <div className='flex gap-x-4 justify-center text-white'>
        Close
      </div>
    </Button>
    </div>
  }

  const renderSplitModal = (status: boolean): ReactElement => {
    return <Modal isOpen={status} onClose={() => setOpenSplit(false)} backgroundColor='bg-mtn-blue-250' backdropColor='bg-mtn-gray-650 opacity-60'>
      <div className='py-8 px-4 bg-mtn-blue-250'>
        <Split nextPage={'payment'} onClick={() => setOpenSplit(false)} disableCharityLogos />
      </div>
    </Modal>
  }

  const renderBreakdownModal = (status: boolean): ReactElement => {
    const nightBreakdown = getSplitBreakdown(plan)

    return <Modal isOpen={status} onClose={() => setOpenBreakdown(false)} backgroundColor='bg-mtn-blue-250' backdropColor='bg-mtn-gray-650 opacity-60'>
      <div className='py-8 px-10 overflow-auto max-h-96 bg-mtn-blue-250 text-white'>
        <h2 className='font-bold my-2'>Daily Donation Breakdown</h2>
        <div className="flex justify-between gap-x-8 border-b-1 border-mtn-gray-300 py-2 text-white">
          <p>Night</p>
          <p>Amount</p>
        </div>
        {nightBreakdown.nights.map(d => {
          return (<div key={d.number} className='flex justify-between gap-x-8 border-b-1 border-mtn-gray-300 py-2 text-white'>
            {/* we add a +1 so the first night is set as Night 1 (on the frontend) instead of Night 0 (which it is ideally) Night 0 means the first night (First Taraweeh Night) before the first fasting day. */}
            <p>Night {d.number} - {d.date.toLocaleString(DateTime.DATE_MED)}</p>   <p>{displayCurrency({ amount: d.amount, currency: plan.charity.currency })}</p>
          </div>)
        })}
        <div className='py-2'>
          If there are 30 nights this year, we'll take a payment that night equal to your 28th night payment.
        </div>
      </div>
      {renderCloseButton(() => setOpenBreakdown(false))}
    </Modal>
  }

  const renderNightBreakdownSection = (): ReactElement => {
    return <div className="bg-mtn-blue-75 w-full p-4">
            <div className="flex w-full justify-between" onClick={() => setOpenBreakdown(true)}>
                <span className="text-lg font-bold">See daily breakdown</span>
                <img src={ArrowUp} alt="donation split open icon" className="transform rotate-90"/>
            </div>
          </div>
  }

  const renderSplitSection = (): ReactElement => {
    return <div className="bg-mtn-blue-75 w-full p-4" onClick={() => setOpenSplit(true)}>
            <div className="flex w-full justify-between">
                <span className="text-lg font-bold">Your donation split</span>
                <img src={ArrowUp} alt="donation split open icon" className="transform rotate-90"/>
            </div>
            <span>{mtnSplitTexts[plan.split]}</span>
          </div>
  }

  const renderDonationCauseAmountsSection = (): ReactElement => {
    const causeAmountExists = Object.values(plan.causes).some(amount => amount > 0)

    return <>
        {causeAmountExists ? <section className="bg-mtn-blue-75 w-full p-4">
            <div className="flex w-full flex-col">
                <span className="text-xl font-bold">Your Donation</span>
                <span className="text-lg font-bold">Causes</span>
                <div className="flex flex-col gap-1 mt-1">
                  {Object.keys(plan.causes).map((cause, index) => plan.causes[cause] > 0
                    ? <div key={index} className="flex justify-between w-full text-xs">
                      <span>{plan.charity.causes.find((charityCause) => charityCause.cause_id === cause)?.title ?? 'Something'}</span>
                      <span>{displayCurrency({ amount: plan.causes[cause], currency: plan.charity.currency })}</span>
                    </div> : null
                  )}
                </div>
            </div>
          </section> : null}
    </>
  }

  const renderDonationBreakdownSection = (): ReactElement => {
    return <section className="bg-mtn-blue-75 w-full p-4">
            <div className="flex w-full flex-col">
                <span className="text-xl font-bold">Breakdown</span>
                <div className="flex flex-col gap-1 mt-1">
                  <div className="flex justify-between w-full text-xs font-bold">
                    <span>Subtotal (Causes)</span>
                    <span>{displayCurrency({ amount: getCausesTotal(plan), currency: plan.charity.currency })}</span>
                  </div>
                  <div className="flex justify-between w-full text-xs">
                    <span className="w-32">Tips {plan.paymentDetails.tipPercent !== Tip.Other && `(${tipValues[plan.paymentDetails.tipPercent]}%)`}</span>
                    <span data-test-id={MytennightsTestId.addTipButton} className="underline footer-links cursor-pointer" onClick={toggleTip}>{payTip ? 'Remove' : 'Add'}</span>
                    <span>{displayCurrency({ amount: payTip ? tipAmount : 0, currency: plan.charity.currency })}</span>
                  </div>
                  <div className="flex justify-between w-full text-xs items-center relative">
                    <div className="flex items-center">Transaction Fee <MessageBox infoIcon variant={Variant.mtn} classname="bg-mtn-gray-450 text-white border-0">{getTransactionFeeToolTipText()}</MessageBox></div>
                    <span className="underline footer-links cursor-pointer" onClick={toggleFees}>{payFees ? 'Remove' : 'Add'}</span>
                    <span>{displayCurrency({ amount: payFees ? totalFeesAmount : 0, currency: plan.charity.currency })}</span>
                  </div>
                </div>
            </div>
          </section>
  }

  const renderMobile = (): ReactElement => {
    return <div className="p-4 pt-5 bg-mtn-blue-250 text-white flex flex-col items-center justify-center h-auto rounded-2xl pb-7 w-full">
        <div className="flex justify-between w-full items-center">
            <span className="text-lg">Total</span>
            <span className="font-bold text-2xl">{displayCurrency({ amount: donationTotal, currency: plan.charity.currency })}</span>
        </div>

        <div className={`flex items-center bg-mtn-blue-75 rounded-2xl text-xs gap-x-1 py-1 px-2 mt-2 mb-4`} onClick={() => setExpanded(!expanded)}>
            <span>Summary</span>
            <img src={ArrowUp} alt="expand" className={`${expanded ? '' : 'transform rotate-180'}`}/>
          </div>
        <div className={`flex flex-col gap-3 ${expanded ? 'max-h-screen mb-9 mt-2' : 'max-h-0'} w-full transition-all duration-500 overflow-auto`}>

          {renderSplitSection()}

            {renderNightBreakdownSection()}

          {showDonationCauses && renderDonationCauseAmountsSection()}

            {showDonationBreakdown && renderDonationBreakdownSection()}

        </div>

        {preSubmitError && <p className="mb-2 text-xs text-center text-mtn-orange">{preSubmitError}</p>}
        {renderActionButton()}
        {postSubmitError && <p className="mt-4 text-xs text-center text-mtn-orange">{postSubmitError}</p>}
      </div>
  }

  const renderDesktop = (): ReactElement => {
    return <div className={`p-4 pt-10 bg-mtn-blue-250 text-white flex flex-col items-center transition-all duration-500 rounded-2xl gap-y-2`}>
      <span className={`font-bold text-4xl`}>{displayCurrency({
        amount: donationTotal,
        currency: plan.charity.currency
      })}</span>

      <h1 className="text-lg font-medium">Total donation today</h1>

      <div data-test-id={MytennightsTestId.summaryToggleBtn} className={`flex items-center bg-mtn-blue-75 rounded-2xl text-xs gap-x-1 py-1 px-2 my-1`} onClick={() => setExpanded(!expanded)}>
              <span>Summary</span>
              <img src={ArrowUp} alt="expand" className={`${expanded ? '' : 'transform rotate-180'}`}/>
      </div>

      <div className={`flex flex-col gap-3 max-h-0 ${expanded ? 'max-h-700 mb-6' : ''} w-full transition-all duration-500 overflow-auto`}>

        {renderSplitSection()}

        {renderNightBreakdownSection()}

        {showDonationCauses && renderDonationCauseAmountsSection()}

        {showDonationBreakdown && renderDonationBreakdownSection()}

      </div>

      {preSubmitError && <p className="mb-2 text-xs text-center text-mtn-orange">{preSubmitError}</p>}
      {renderActionButton()}
      {postSubmitError && <p className="mt-2 text-xs text-center text-mtn-orange">{postSubmitError}</p>}
  </div>
  }

  return <>
    {renderBreakdownModal(openBreakdown)}
    {renderSplitModal(openSplit)}
    {isMobile ? renderMobile() : renderDesktop()}
  </>
}
