import { type FC, type SyntheticEvent, useState } from 'react'
import { type CharityCampaign } from '@shamaazi/mytennights'
import { ConstructCharityImageUrl } from '@shamaazi/mytennights'

export const CharityLogo: FC<{ charity: CharityCampaign | undefined }> = ({ charity }) => {
  const [showFallback, setShowFallback] = useState(false)
  const coverURL = ConstructCharityImageUrl(charity?.charity_id as string, false, charity?.logo_image as string)

  return <>
    {showFallback && <h3 className="text-xl text-center md:text-3xl text-mtn-blue-500">{charity?.charity_name}</h3>}
    <img
      src={coverURL}
      alt={charity?.charity_name}
      className="charityLogo mx-auto mt-4"
      onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
        if (!showFallback) {
          e.currentTarget.src = '/assets/charities/fallback.svg'
          setShowFallback(true)
        }
      }} />
  </>
}
