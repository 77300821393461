import { Button } from '@lib/components'
import { displayTidyCurrency, UrlRoutes } from '@lib/services'
import { ActiveExperiments, Card, GiveMatchLogoVariants, MTNtracking } from '@shamaazi/mytennights'
import { type FC, useEffect, useRef, useState, type ReactElement } from 'react'
import ShareLinkModal from '~/components/shareLinkModal/ShareLinkModal'
import { type ShareDetails } from '~/hooks/useSharePageDetails'
import { optIntoGivematch } from '~/service/givematch'
import givematch from '~/img/givematch-logo.png'
import { useAuth } from '@lib/hooks'
import { ComponentsTestId } from '@lib/testing'
import { useExperiment } from '@lib/react-ab-test'

const OverlappingBackground: FC = ({ children }) => {
  return <div className='py-24 px-6 sm:px-20'>
    {children}
  </div>
}

const Heading: FC = ({ children }) => {
  return <div className="text-3xl">
    {children}
  </div>
}

function getGivematchText (donation: string, total: string): JSX.Element {
  return <Heading>
    <span className="text-gm-green"> Your {donation} will become {total}</span> when you get 2 friends to donate
  </Heading>
}

export const GivematchShareContent: FC<{ details: ShareDetails }> = ({ details }) => {
  const { user } = useAuth()
  const [showShareLinks, setShowShareLinks] = useState(false)
  const { activeVariant: activeLogoVariant } = useExperiment(ActiveExperiments.GiveMatchLogo)

  useEffect(() => {
    MTNtracking.viewWidget()
  }, [])

  const optedIn = useRef(false)

  const fireAndForget = async (): Promise<void> => {
    setShowShareLinks(true)
    MTNtracking.widgetButtonClick(user?.email, UrlRoutes.buildShareUrl(details.shareCode), false, details.lengthChoice, details.splitChoice, details.giftAid, details.wakf, details.wakfAmount, details.fridayGiver, details.fridayGiverAmount)
    if (!optedIn.current) {
      try {
        await optIntoGivematch(details.shareCode)
      } catch {
        // Swallowing errors because we don't care a huge amount.
      }

      optedIn.current = true
    }
  }

  const money = (amount: number): string => {
    return displayTidyCurrency(amount, details.currency)
  }

  const renderShareDetails = (): ReactElement => {
    return <Card variant="mtn" className="z-20 mt-10 p-6 flex m-auto md:max-w-2xl max-w-lg bg-white border font-givematch border-mtn-gray-400">
      <div className="h-full w-full tracking-tighter text-center">
        <div className="flex flex-col items-center justify-center text-center">
          {activeLogoVariant === GiveMatchLogoVariants.withLogo && <img className="w-48 mt-4" src={givematch} alt="Givematch" />}
          <p className="text-xl my-12 md:w-4/5 font-bold leading-tight md:text-6xl text-gm-green-900">
            {getGivematchText(money(details.total), money(details.total + details.matchedAmount))}
          </p>
          {/* eslint-disable @typescript-eslint/no-misused-promises */}
          <div className="w-full md:px-0">
    <Button
      data-test-id={ComponentsTestId.inviteLinkShareButton}
      variant="mtn-share-button"
      className="bg-gm-yellow rounded-lg border-gm-yellow text-gm-green-900 text-xs md:text-xl mt-5 w-full px-10 md:px-0 mb-7"
      onClick={async () => fireAndForget()}
    >
      <div className="flex gap-x-2 md:gap-x-4 text-xl md:py-1 md:px-6 w-full font-bold justify-center">
        SHARE INVITE
      </div>
    </Button>

<div className="font-poppins font-normal text-black text-xs">
  <p className="mb-2">Note: Terms & Conditions apply.</p>
  <a className="font-semibold" href="/faq" target="_blank">Click here to read more about Givematch x MyTenNights.</a>
</div>

  </div>
        </div>
      </div>
    </Card>
  }

  return <>
    <OverlappingBackground>
      {!showShareLinks && renderShareDetails()}
      <div className="flex-col mt-5 md:mt-0 justify-around items-center px-4">
        <div className="flex flex-col justify-center items-center">
          <h1 className="md:mt-4 text-base text-center md:text-2xl text-white">
            You&apos;re all set for Laylatul Qadr
          </h1>
        </div>
        <p className="text-xs md:text-sm mt-1 md:mt-0 text-center text-white">
          We’ve sent you a confirmation email.
        </p>
      </div>
    </OverlappingBackground>

    {showShareLinks && <ShareLinkModal shareCode={details.shareCode} lengthChoice={details.lengthChoice} splitChoice={details.splitChoice} giftAid={details.giftAid} wakf={details.wakf} wakfAmount={details.wakfAmount} isDashboard={false} hideModal={() => setShowShareLinks(false)} fridayGiver={details.fridayGiver} fridayGiverAmount={details.fridayGiverAmount} />}
  </>
}
