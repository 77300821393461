import { type Dispatch, type FC, type SetStateAction, useState, type ReactElement } from 'react'
import { Card, type CharityCampaign, ConstructCharityImageUrl, Flair, MTNtracking } from '@shamaazi/mytennights'
import { MytennightsTestId } from '@lib/testing'
import { useAuth } from '@lib/hooks'
import { useCheckoutPlan } from '@shamaazi/mytennights'
import { isMultiCharity } from '@lib/services'
import multiCharityLogo from '~/img/mtn-fund.svg'

const fallBackCoverURL = (charityID: string): string => `/assets/charities/${charityID}/${charityID}_cover.svg`

interface props {
  charity: CharityCampaign
  onSelect: () => void
  setHasCoverImage?: Dispatch<SetStateAction<boolean>>
}

export const CharityCard: FC<props> = ({ charity, onSelect, setHasCoverImage }) => {
  const coverImage = ConstructCharityImageUrl(charity.charity_id, true)
  const [imageErrorLevel, setImageErrorLevel] = useState(0)

  const { user } = useAuth()
  const { plan } = useCheckoutPlan()
  const filteredCauses = isMultiCharity(charity.charity_id)
    ? charity.causes.filter(cause =>
      ['Zakat', 'Sadaqah', 'Where need is the Greatest'].includes(cause.title)
    )
    : charity.causes

  const causes = filteredCauses.map(c => (
    <div key={c.cause_id} className="bg-mtn-blue-100 px-3 py-1 rounded-md text-mtn-blue-150 text-sm">
      {c.title}
    </div>
  ))

  const CharityImageElement = ({ src, alt, onError }: { src: string, alt: string, onError: () => void }): JSX.Element => (
    <img
      className="rounded-t-lg w-full h-52 object-cover bg-white"
      src={src}
      alt={alt}
      onError={onError}
    />
  )

  const generateImage = (charity: CharityCampaign): ReactElement => {
    if (isMultiCharity(charity.charity_id)) {
      return (
        <div className="w-full flex flex-col justify-center items-center text-2xl gap-y-5 relative">
          <div className="relative w-full">
            <img className="rounded-t-lg object-cover h-52 w-full" src={multiCharityLogo} alt={charity.charity_id} />
            <div className="absolute top-2 right-2 bg-mtn-yellow text-mtn-blue-800 p-1 font-medium text-xs rounded flex items-center shadow-md">
              <span className="px-1">POPULAR CHOICE</span>
              <Flair />
            </div>
          </div>
        </div>
      )
    }
    if (imageErrorLevel === 0) {
      return (
        <CharityImageElement src={coverImage} alt={charity.charity_id} onError={() => {
          setImageErrorLevel(1)
          setHasCoverImage?.(false)
        }} />
      )
    }
    if (imageErrorLevel === 1) {
      return (
        <CharityImageElement src={fallBackCoverURL(charity.charity_id)} alt={charity.charity_id} onError={() => setImageErrorLevel(2)} />
      )
    }
    return (
      <div className="w-full h-52 flex flex-col rounded-lg justify-center items-center text-2xl gap-y-5 bg-white">
        <img src="/assets/charities/fallback.svg" alt={charity.charity_id} className="bg-white" />
        <p className="text-mtn-blue text-center">{charity.charity_name}</p>
      </div>
    )
  }

  return <Card
    data-test-id={MytennightsTestId.charityCard_ + charity.charity_id}
    variant="mtn" className="flex flex-col duration-300 cursor-pointer bg-white shadow-none">
    <div data-test-id={MytennightsTestId.charityCardSelectButton_ + charity.charity_id}
      onClick={() => {
        MTNtracking.selectCharity(user?.email, charity.charity_name, charity.charity_id, MTNtracking.getNightsLengthChoice(plan.totalNight.value), plan.split)
        onSelect()
      }}>
      {generateImage(charity)}
      <div className="flex flex-col flex-grow p-4">
        <h4 className="text-lg text-mtn-blue-150 font-bold"> {isMultiCharity(charity.charity_id) ? 'The MyTenNights Fund' : charity.charity_name}</h4>
        {isMultiCharity(charity.charity_id) && <span className="py-3 text-mtn-blue-150 text-fg-small-body-text-v2 font-normal">Spread your donations across multiple charities chosen by us.</span>}
        <div className="flex flex-wrap gap-2 mt-2 text-sm text-mtn-grey-300 capitalize">
          {causes}
        </div>
      </div>
    </div>
  </Card>
}
