import { type FC } from 'react'

import { type Cause, type CharityCampaign, useCheckoutPlan } from '@shamaazi/mytennights'
import { MytennightsTestId } from '@lib/testing'
import { causeIcons } from '~/pages'
interface props {
  charity: CharityCampaign
  causes: Cause[]
}

export const CauseCard: FC<props> = ({ causes, charity }) => {
  const { plan, setPlan } = useCheckoutPlan()
  const toggleCauseSelection = (selectedCause: Cause): void => {
    const isSelected = plan.selectedCauses?.some(cause => cause.cause_id === selectedCause.cause_id) || false
    if (!isSelected && plan.selectedCauses.length === 4) {
      return
    }
    const updatedSelectedCauses = isSelected
      ? plan.selectedCauses.filter(cause => cause.cause_id !== selectedCause.cause_id)
      : [...(plan.selectedCauses || []), selectedCause]
    setPlan({ ...plan, selectedCauses: updatedSelectedCauses, charity })
  }
  const causeCards = causes.filter(c => c.title.toLowerCase() !== 'sadqah jariyah').map(c => {
    return <div key={c.title} onClick={() => toggleCauseSelection(c)} className={`h-28 text-mtn-blue-150 py-4 px-3 rounded-lg border-mtn-gray-400 bg-white cursor-pointer hover:shadow ${plan.selectedCauses?.some((cause) => cause.cause_id === c.cause_id) ? 'border-mtn-blue-dawn border-2 bg-mtn-gray-350' : 'border'} ${!plan.selectedCauses?.some((cause) => cause.cause_id === c.cause_id) && plan.selectedCauses?.length === 4 ? 'opacity-50 cursor-not-allowed' : ''}`}>
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center capitalize">
          <img
            src={causeIcons[c.title]}
            alt={c.title}
            className="w-5 h-5 mr-2"
          />
          <span className={`font-bold text-lg text-mtn-blue-${plan.selectedCauses?.some((cause) => cause.cause_id === c.cause_id) ? '250' : '150'}`}>
            {c.title}
          </span>
        </div>
      </div>
      <div className="text-sm mt-2 font-medium"> {c.description} </div>
    </div>
  })
  return <div
  data-test-id={MytennightsTestId.causeCard_}
  className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full"
>
  {causeCards}
</div>
}
