import { type FC, useRef, useState, type ReactElement, useEffect } from 'react'
import {
  type Cause,
  useCheckoutPlan,
  getMinimalPlanAmountText,
  isBreakdownValid,
  MTNtracking,
  useAuthWithSignup,
  DafDisclaimer,
  ProductName,
  CharityDisclaimer,
  isCharityNotManagedByDaf,
  getToggleButtonStyle, displayableStartDate, CampaignSetup,
  ScrollHash,
  AutoEnroll,
  autoEnrollMTNErrorMsg,
  calculateTip,
  Tip,
  fixTipValue,
  MessageBox,
  type PaymentFormData,
  getTipAmount,
  useResponsive,
  Variant,
  IRUK
} from '@shamaazi/mytennights'
import { CheckoutProgress, Navigation, Pages, WithFooter } from '~/components'
import { Button, InputVariantStyles, Page, SelectWithRef } from '@lib/components'
import { type Currency, displayCurrency, getCurrencySymbol, GetCurrencyByString, isMultiCharity, MultiCharity, isWidget } from '@lib/services'
import { MytennightsTestId, PaymentFormTestId } from '@lib/testing'
import CurrencyInput from 'react-currency-input-field'
import { Header } from '~/components/header'
import { CheckoutSummary } from '~/components/CheckoutSummary'
import { useNavigate } from 'react-router-dom'
import arrowRight from '~/img/arrow-right.svg'
import { scrollToHash } from '@shamaazi/mytennights'
import { useForm } from 'react-hook-form'
import { EverGiveContributionExperiment } from '~/components/EverGiveContribution'
import { EverGiveCauseWidget } from '~/components/EverGiveCauseWidget'

interface CauseInputProps {
  cause: Cause
  value: number
  currency: Currency
  onChange: (v: number) => void
  trackEvent: () => void
}

const CauseInput: FC<CauseInputProps> = ({ cause, value, onChange, currency, trackEvent }) => {
  const input = useRef<HTMLInputElement>(null)

  const updateAmount = (value: string): void => {
    const n = parseFloat(value)
    const v = (!isNaN(n) && n > 0 && Math.round(n * 100)) || 0
    if (v < 0 || v > 99999999) {
      return
    }
    (v) /* eslint-disable-line @typescript-eslint/no-unused-expressions */
    onChange(v)
  }

  return <div
    data-test-id={MytennightsTestId.cause_ + cause.title.toLowerCase()}
    className={'font-medium col-span-1'}>
    <div className="flex flex-row gap-y-6 py-3 pl-5 pr-3 justify-between items-center bg-mtn-gray-350 border border-mtn-gray-350 rounded-lg h-full">
      <h2 className="text-sm md:text-lg font-bold text-left text-mtn-blue-800 w-5/12 md:w-7/12">{cause.title}</h2>
      <form className="flex bg-white flex-row border-mtn-gray-550 rounded-md w-1/2 md:w-4/12">
        <span className='w-2/5 flex items-center px-3 text-base md:text-xl font-semibold text-right focus:outline-none whitespace-nowrap'>{getCurrencySymbol(GetCurrencyByString(currency))}</span>
        <CurrencyInput
          data-test-id={MytennightsTestId.causeInput_ + cause.title.toLowerCase()}
          defaultValue={value > 0 ? value / 100 : ''}
          id="cause amount"
          className="w-3/4 text-xl font-bold rounded-md p-2 py-1 focus:outline-none text-gm-black border-mtn-gray-550"
          placeholder={'0.00'}
          step={0.01}
          onValueChange={(value) => updateAmount(value ?? '')}
          ref={input}
          onBlur={trackEvent}
          intlConfig={{ locale: 'en-UK' }}
        />
      </form>
    </div>
  </div>
}

export const Causes: FC<{ nextPage: string }> = ({ nextPage }) => {
  const { user } = useAuthWithSignup()
  const { plan, setPlan } = useCheckoutPlan()
  const navigate = useNavigate()
  const { isMobile } = useResponsive()
  const donationTotal = Object.values(plan.causes).reduce((acc, v) => acc + v, 0)
  const [minimumAmountError, setMinimumAmountError] = useState('')
  const [egError, setEgError] = useState('')
  const [deletedAmountError, setDeletedAmountError] = useState('')
  const [autoEnrolError, setAutoEnrolError] = useState(false)
  const [offsetSelected, setOffsetSelected] = useState<number>(plan.startDateOffset)
  const ramadanStartDateOffsets = [0, 1]
  const [isEGDisableState, setIsEGDisableState] = useState(false)

  const setCauseValue = (causeID: string, value: number): void => {
    // Set error if all other causes have amount 0 and the current cause is being set to 0
    const { [causeID]: currentCause, ...restCauses } = plan.causes
    if (Object.values(restCauses).every(causeAmount => causeAmount === 0) && value === 0) {
      setDeletedAmountError(`Please enter your donation amount to continue`)
      setMinimumAmountError('')
    } else {
      setDeletedAmountError('')
    }

    setPlan({ ...plan, causes: { ...plan.causes, [causeID]: value } })
  }

  const defaultTipPercent = Tip.TenPercent

  const form = useForm<PaymentFormData>({
    defaultValues: {
      tipPercent: defaultTipPercent,
      tipValue: getTipAmount(plan.causes, defaultTipPercent, 0)
    }
  })

  const { register, watch, setValue } = form
  const [tipPercent, tipValue] = watch(['tipPercent', 'tipValue'])
  useEffect(() => {
    setPlan({ ...plan, paymentDetails: { ...plan.paymentDetails, tipPercent, tipValue: fixTipValue(tipPercent === Tip.Other ? tipValue : getTipAmount(plan.causes, tipPercent, 0)) } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipPercent, tipValue])

  const filteredCharityCauses = isMultiCharity(plan.charity.charity_id)
    ? plan.charity.causes.filter(cause =>
      ['zakat', 'sadaqah', 'where need is the greatest'].includes(cause.title.toLowerCase())
    )
    : plan.charity.causes

  const filteredEGCauses = (isMultiCharity(plan.charity.charity_id) ? plan.charity.causes.filter(cause => ['sadqah jariyah'].includes(cause.title.toLowerCase())) : [])

  const causes = (plan.selectedCauses.length > 0 ? plan.selectedCauses : filteredCharityCauses).map((cause) => {
    const value = plan.causes[cause.cause_id] ?? 0
    return <CauseInput
      key={cause.cause_id}
      cause={cause}
      currency={plan.charity.currency}
      value={value}
      onChange={(value) => {
        setCauseValue(cause.cause_id, value)
      }}
      trackEvent={() => {
        MTNtracking.addCause(user?.email, cause.title, value, plan)
      }
      }
    />
  })
  const egCauses = filteredEGCauses.map((cause) => {
    const value = plan.causes[cause.cause_id] ?? 0
    return <CauseInput
      key={cause.cause_id}
      cause={cause}
      currency={plan.charity.currency}
      value={value}
      onChange={(value) => {
        setCauseValue(cause.cause_id, value)
      }}
      trackEvent={() => {
        MTNtracking.addCause(user?.email, cause.title, value, plan)
      }
      }
    />
  })

  const renderRamadanStartDate = (): ReactElement => {
    const getDetailsToggleButtonStyle = (isToggle: boolean): string => `${getToggleButtonStyle(isToggle)} w-1/3 md:text-lg text-xs`
    return <section className="bg-white border border-mtn-gray-250 p-6 rounded-lg">
      <div className='text-fg-blue-800 flex flex-row relative justify-between mb-4'>
        <div className='flex flex-col'>
          <h2 className="text-lg font-bold text-fg-blue-800">{CampaignSetup.defaultRamadanStartDate.diffNow('days').days > 0 ? 'Your Ramadan start date' : 'When did you start fasting?'}</h2>
          <p className='text-sm text-left text-fg-blue-800'>We’ll make sure your payments are taken on the night before your chosen date</p>
        </div>
      </div>
      <div className="flex w-full justify-start items-stretch h-12 gap-x-2">
        {ramadanStartDateOffsets.map(offset => <button
          name="ramadan-start"
          data-test-id={MytennightsTestId.ramadanOffsetButton_ + offset.toString()}
          key={offset.toString()}
          onClick={() => {
            setPlan({ ...plan, startDateOffset: offset })
            setOffsetSelected(offset)
          }}
          className={getDetailsToggleButtonStyle(offsetSelected === offset)}
        >{displayableStartDate(offset)}</button>
        )}
      </div>
    </section>
  }
  const [customTipError, setCustomTipError] = useState('')

  const renderContinueBtn = (): ReactElement => {
    return <div className='flex w-full justify-center'>
      <Button
        data-test-id={MytennightsTestId.continueButton}
        variant="mtn-donate-now"
        className='bg-gm-yellow button-disabled-color text-mtn-blue-800 disabled:text-mtn-blue-800 text-2xl py-3 w-72 uppercase font-bold'
        disabled={!(donationTotal > 0 && donationTotal <= 99999999) ||
          (plan.paymentDetails.tipPercent === Tip.Other && customTipError !== '')}
        onClick={() => {
          const isValid = isBreakdownValid(plan)
          if (!isValid) {
            setMinimumAmountError(`Amount entered is too low, minimal amount is ${getMinimalPlanAmountText(plan)}`)
            scrollToHash(ScrollHash.lowAmountEntered)
          } else if (isEGDisableState) {
            setEgError('Please select your EverGive contribution.')
            scrollToHash(ScrollHash.egContributionNotSelected)
          } else {
            setPlan({ ...plan, paymentDetails: { ...plan.paymentDetails, tipPercent, tipValue: plan.paymentDetails.payTip ? fixTipValue(tipPercent === Tip.Other ? Math.floor(tipValue) : getTipAmount(plan.causes, tipPercent, 0)) : 0 } })
            MTNtracking.confirmAmount(user?.email, plan)
            const partner = MTNtracking.getPartner().partner
            navigate(Navigation.prefixPartnerApplicableRoute(nextPage, partner))
          }
        }}
      >
        <div className='flex gap-x-4 justify-center'>
          Continue <img src={arrowRight} alt='continue' />
        </div>
      </Button>
      {autoEnrolError && <div className="mt-3 text-sm text-center text-mtn-red">
        {autoEnrollMTNErrorMsg}
      </div>}
    </div>
  }

  const handleCustomTipChange = (value: string): void => {
    const customTipAmount = Number(value)
    if (!value || customTipAmount < 0) {
      setValue('tipValue', 0)
      setCustomTipError('Sorry, the minimum tip is 0.00')
      return
    }
    if (fixTipValue(customTipAmount) >= 999999) {
      setCustomTipError('Sorry, we don\'t accept tips this large!')
      return
    }
    setCustomTipError('')
    setValue('tipValue', fixTipValue(customTipAmount * 100))
  }

  return <WithFooter backgroundColour='mtn-gray-900'>
    <Header />
    <CheckoutProgress step={Pages.Split} />
    <Page className="md:flex md:justify-center bg-mtn-gray-900">
      <div className="py-4 md:px-10 px-4 sm:px-0 flex flex-col justify-start gap-8 lg:w-4/5">
        {!isMobile && <div className="flex flex-row gap-4 w-full justify-center">
          <h1 className="text-xl w-96 text-center font-semibold text-mtn-blue-800">How should we split your donations?</h1>
        </div>}
        <div className='md:grid lg:grid-cols-2 md:grid-cols-2 md:gap-x-6'>
          <div className={'flex flex-col gap-y-4'}>
            <div className='flex flex-col gap-y-4 border border-mtn-gray-250 p-4 rounded-lg bg-white'>
              <div className="flex justify-between items-center relative">
                <h1 className='text-fg-blue-800 font-bold text-base'>Your Donations</h1>
                <MessageBox infoIcon tooltipBorder>
                  All donations are tax-deductible
                </MessageBox>
              </div>
              <div id={ScrollHash.lowAmountEntered} className="flex flex-col gap-y-2">
                {causes}
              </div>
            </div>
            {/* This EverGiveCauseWidget is added as an additional donation to EG only for MCP donors */}
            {plan.selectedCauses.length === 0 && isMultiCharity(plan.charity.charity_id) && <EverGiveCauseWidget egCauses={egCauses}/>}

            {plan.charity.charity_id !== IRUK && !isWidget && !isMultiCharity(plan.charity.charity_id) &&
              <EverGiveContributionExperiment setIsEGDisableState={setIsEGDisableState} egError={egError} setEgError={setEgError} />}
            <form>
              <section className="p-6 flex rounded-xl bg-white border border-mtn-gray-250">
                <div className='flex flex-col justify-between w-full'>
                  <div className='flex flex-row justify-between items-center w-full md:mb-0 relative'>
                    <p className="text-mtn-blue-800 text-base font-bold">Your tip fuels our mission</p>
                    <MessageBox infoIcon tooltipBorder>
                      <div>
                        <p className="font-light">
                          Your tip keeps the platform running smoothly, ensuring thousands can automate their giving and never miss the rewards of Laylatul Qadr.
                        </p>
                        {plan.charity.charity_id === MultiCharity.gb &&
                          <p className="font-light">
                            Your tip is gift aid eligible and is received as a donation to our charitable DAF partner: Compassion for Creation UK, registered charitable organisation number 1204164, for The MyTenNights Fund and then spread across
                            various other registered charities or causes. The gift aid from your tip donation will also be passed on to the fund.
                          </p>}
                        {plan.charity.charity_id !== MultiCharity.gb && plan.charity.charity_id?.endsWith('-uk') ? (
                          <p className="font-light">
                            Your tip is gift aid eligible and is received as a donation to our charitable DAF partner: Mercy Mission UK,
                            registered charitable organisation number 1122922. The gift aid from your tip donation will also be passed on to the fund.
                          </p>
                        ) : null}
                      </div>
                    </MessageBox>
                  </div>
                  <div className='flex flex-col my-3 sm:mt-0 md:mt-5 w-full'>
                    <div className="w-full">
                      <SelectWithRef
                        data-test-id={PaymentFormTestId.tipDropdown}
                        aria-label="Tip percent"
                        className="w-full pr-12 pl-3 font-medium rounded-lg bg-white border-mtn-gray-250"
                        variant="mtn"
                        {...register('tipPercent', { required: true })}>
                        <option value={Tip.FivePercent}>{displayCurrency({
                          amount: calculateTip(plan.causes, 5),
                          currency: plan.charity.currency
                        })} (5%)
                        </option>
                        <option value={Tip.TenPercent}>{displayCurrency({
                          amount: calculateTip(plan.causes, 10),
                          currency: plan.charity.currency
                        })} (10%)
                        </option>
                        <option value={Tip.FifteenPercent}>{displayCurrency({
                          amount: calculateTip(plan.causes, 15),
                          currency: plan.charity.currency
                        })} (15%)
                        </option>
                        <option value={Tip.Other}>Other</option>
                      </SelectWithRef>
                    </div>
                    <div className={`justify-end mt-4 ${tipPercent === Tip.Other ? '' : 'hidden'}`}>
                      <div className="relative">
                        <span className="absolute transform -translate-y-1/2 pointer-events-none left-3 top-2/4 whitespace-nowrap">
                          {getCurrencySymbol(plan.charity.currency)}
                        </span>
                        <CurrencyInput
                          data-test-id={PaymentFormTestId.tipAmount}
                          defaultValue={tipValue / 100}
                          id="custom tip amount"
                          className={`${InputVariantStyles.mtn} w-full text-left rounded-xl bg-white pl-12`}
                          placeholder={'0.00'}
                          step={0.01}
                          onValueChange={(value) => handleCustomTipChange(value ?? '')}
                          intlConfig={{ locale: 'en-UK' }}
                        />
                      </div>
                    </div>
                    {tipPercent === Tip.Other && customTipError && <div
                      className="mt-2 text-xs text-right text-mtn-red min-h-xs">{customTipError}</div>}
                  </div>
                  <p className="mt-1 text-xs text-fg-blue-800">Applied to all donation subscriptions.</p>
                </div>
              </section>
            </form>

            {
              renderRamadanStartDate()
            }
            <div className='mb-5'>
              <AutoEnroll error={autoEnrolError} setError={setAutoEnrolError} platform={Variant.mtn} />
            </div>
          </div>
          <div>
            <CheckoutSummary renderActionButton={renderContinueBtn} preSubmitError={deletedAmountError} postSubmitError={minimumAmountError} showDonationCauses showDonationBreakdown />
          </div>
        </div>
        {isCharityNotManagedByDaf(plan.charity.charity_id) ? <CharityDisclaimer charity={plan.charity} />
          : <DafDisclaimer country={plan.charity.country} product={ProductName.myTenNights} charityId={plan.charity.charity_id} />
        }
      </div>
    </Page>
  </WithFooter>
}
