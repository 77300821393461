export const ClaritySnippet = (isProd: boolean, prodId: string, stagingId: string): void => {
  (function (c: any, l: Document, a: string, r: string, i: string): void {
    c[a] ??= function (): void { (c[a].q ??= []).push(arguments) }

    const t = l.createElement(r) as HTMLScriptElement
    t.async = true
    t.src = 'https://www.clarity.ms/tag/' + i

    const y = l.getElementsByTagName(r)[0]
    y?.parentNode?.insertBefore(t, y)
  })(window, document, 'clarity', 'script', isProd ? prodId : stagingId)
}
