import { type FC, useEffect } from 'react'

import { WithFooter } from '~/components/footer'
import { useSharePageDetails } from '~/hooks/useSharePageDetails'
import { useCheckoutPlan } from '@shamaazi/mytennights'
import { GivematchShareContent, Header, ShareContent } from '~/components'

const Share: FC = () => {
  const { sharePageDetails: details } = useSharePageDetails()
  const { resetPlan } = useCheckoutPlan()
  useEffect(resetPlan, [resetPlan])
  const showGivematch = details.matchPotBalance >= details.matchedAmount

  return <WithFooter>
    <div className='mtn-share-full h-screen'>
      <Header transparent showProfileLink />
      {showGivematch && <GivematchShareContent details={details} />}
      {!showGivematch && <ShareContent details={details} />}
    </div>
  </WithFooter>
}

export default Share
