import { type FC, type ReactElement, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import giftAidLogo from '~/img/giftaid.svg'
import giftAidWhiteLogo from '~/img/giftaid-white.svg'

import { Choice, Country, isMultiCharity, MultiCharity, RecaptchaV3 } from '@lib/services'
import { useLocalFormPersist } from '@lib/hooks'
import { Button, Input, Validate, Modal } from '@lib/components'

import { CheckoutProgress, Pages, WithFooter } from '~/components'
import {
  ActiveCampaign,
  calculateTip,
  fixTipValue,
  getCausesTotal,
  getTipAmount,
  PaymentDetails,
  type PaymentFormData,
  PaymentType,
  type ScheduleResponse,
  Tip,
  useAuthWithSignup,
  useCheckoutPlan,
  useSchedulePayment,
  Variant,
  MTNtracking,
  Checkbox,
  ApplicationName,
  ScrollHash,
  PaymentFormProps,
  scrollToHash,
  ERROR_MESSAGES,
  useResponsive
} from '@shamaazi/mytennights'
import { useHiddenZendeskWidget } from '~/hooks/useHiddenZendeskWidget'
import { MytennightsTestId } from '@lib/testing'

import { getTotalFees } from '~/service/fees'
import { Header } from '~/components/header'
import AdditionalContribution from '~/components/AdditionalContribution/AdditionalContribution'
import { CheckoutSummary } from '~/components/CheckoutSummary'

const MTNPaymentPage = 'payment'
const recaptchaV3 = new RecaptchaV3(MTNPaymentPage, ApplicationName.mytennights)

export const Payment: FC<{ nextPage: string }> = ({
  nextPage
}) => {
  const navigate = useNavigate()
  const { plan, resetPlan, setPlan } = useCheckoutPlan()
  const { user, logout } = useAuthWithSignup()
  const { isMobile } = useResponsive()
  useHiddenZendeskWidget()
  const causesTotal = getCausesTotal(plan)
  const form = useForm<PaymentFormData>({
    defaultValues: {
      campaign: ActiveCampaign.myTenNights,
      addressLine1: '',
      country: plan.charity.country,
      email: plan.donorDetails.email ?? user?.email ?? '',
      phone: '',
      countryCode: '',
      firstName: '',
      giftAid: plan.paymentDetails.giftAid,
      taxReceipt: false,
      lastName: '',
      postalCode: '',
      tipPercent: causesTotal > 200000 ? Tip.FivePercent : Tip.TenPercent, // if amount more than 200, 5% tip by default, else 10%
      emailOptIn: plan.emailOptIn,
      charityEmailOptIn: plan.charityEmailOptIn,
      paymentType: undefined,
      ...MTNtracking.getPartner(),
      recaptchaPage: 'payment'
    }
  })

  const resetFormStorage = useLocalFormPersist('checkout_payment', form.watch, form.setValue)
  if (form.getValues('campaign') !== ActiveCampaign.myTenNights) {
    form.reset()
    resetFormStorage()
  }
  const { handleSubmit, watch, setValue } = form

  const [tipPercent, tipValue] = watch(['tipPercent', 'tipValue'])

  const onSuccessfulPayment = (scheduleResponse: ScheduleResponse, formData: PaymentFormData): void => {
    MTNtracking.paymentSuccessful(formData.email, Boolean(user), plan, scheduleResponse, tipAmount, totalFees, formData, scheduleResponse.shareCode)
    queryClient.invalidateQueries('schedules').catch(() => {
    })
    const totalNightValue = MTNtracking.getNightsLengthChoice(plan?.totalNight.value)
    const splitChoiceValue = plan?.split

    // setSharePageDetails is not saving the share details into the session storage at the moment
    // this needs to be looked into, but this fixes the problem for now.
    window.sessionStorage.setItem('share_page_details', JSON.stringify({
      email: scheduleResponse.email,
      shareCode: scheduleResponse.shareCode,
      matchedAmount: scheduleResponse.matchedAmount,
      total: scheduleResponse.total,
      matchPotBalance: scheduleResponse.matchPotBalance,
      currency: plan.charity.currency,
      firstName: formData.firstName,
      lengthChoice: totalNightValue,
      splitChoice: splitChoiceValue,
      giftAid: plan.paymentDetails.giftAid,
      fridayGiver: plan.fridayGivingOptIn === Choice.yes,
      fridayGiverAmount: plan.fridayGiving,
      wakf: plan.wakfOptIn === Choice.yes,
      wakfAmount: plan.wakf
    }))

    resetPlan()
    form.reset()
    resetFormStorage()
    navigate(nextPage)
  }

  const [showMoreGiftAid, setShowMoreGiftAid] = useState(false)
  const [email, setEmail] = useState(plan.donorDetails.email)
  const [emailError, setEmailError] = useState<null | { message: string, colour: string, borderColour: string, fontColour: string }>(null)
  const [disableConfirmationButton, setDisableConfirmationButton] = useState(false)

  const { submit, isLoading, error: paymentError, validationError } = useSchedulePayment(onSuccessfulPayment)
  const [showPaymentErrorModal, setShowPaymentErrorModal] = useState(false)

  const tipAmount = getTipAmount(plan.causes, tipPercent, fixTipValue(tipValue))
  const totalFees = getTotalFees(plan, tipAmount)
  const billingFirstName = form.getValues('firstName')
  const paymentType = form.getValues('paymentType')

  // validation checks to disable/enable confirmation button - checks that certain fields are not empty
  useEffect(() => {
    const emailIsEmpty = plan.donorDetails.email === '' && (user?.email ?? '') === ''
    const isPaymentTypeCard = paymentType === PaymentType.card
    const disableButton = isLoading || emailIsEmpty || !isPaymentTypeCard
    if (disableButton) {
      setDisableConfirmationButton(true)
      return
    }
    setDisableConfirmationButton(false)
  }, [isLoading, plan.donorDetails.email, user?.email, billingFirstName, paymentType])

  useEffect(() => {
    if ((user?.email ?? '') !== '') {
      setValue('email', user?.email ?? '')
      setEmail(user?.email ?? '')
    }
    if (tipPercent === Tip.Other) {
      setValue('tipValue', calculateTip(plan.causes, 3) / 100)
    }
  }, [user, setValue, tipPercent, plan.causes])

  useEffect(() => {
    if (paymentError && paymentError instanceof Error) {
      scrollToHash(ScrollHash.cardDetail)
      // do not show payment error modal for payment validation error
      if (paymentError.message === ERROR_MESSAGES.paymentDetailValidation) {
        return
      }
      setShowPaymentErrorModal(true)
    }
  }, [paymentError])

  const queryClient = useQueryClient()

  const renderPaymentErrorModal = (): ReactElement => {
    return <Modal isOpen={showPaymentErrorModal} onClose={() => setShowPaymentErrorModal(false)} backgroundColor='bg-white'>
    <div className='px-10 pb-10 pt-2 max-h-96 md:max-h-full overflow-auto text-mtn-blue-night flex flex-col'>
      <h1 className='text-2xl text-mtn-blue-night'>Oops!</h1>
      <h1 className='text-2xl text-mtn-blue-night'>Something went wrong</h1>
      <p className='pt-6 text-base text-mtn-blue-night'>Unfortunately, we couldn’t complete your subscription due to possible issues with the information provided, browser settings, or payment method. We apologise for the inconvenience. Please verify your details, try a different browser, or use a different payment method.</p>
     <Button className='mt-6 bg-mtn-white border-1 border-mtn-blue-night' variant="mtn-primary" onClick={() => setShowPaymentErrorModal(false)}><p className='text-mtn-blue-night'>TRY AGAIN</p></Button>
    </div>
  </Modal>
  }

  const validEmail = (): boolean => {
    if (!email) {
      setEmailError({ message: 'Please enter an email to complete your donation.', colour: 'mtn-orange', borderColour: 'mtn-orange', fontColour: 'mtn-orange' })
      scrollToHash(ScrollHash.email)
      return false
    } else if (Validate.validateEmail(email) !== true) {
      setEmailError({ message: 'Email you have entered is invalid', colour: 'mtn-orange', borderColour: 'mtn-orange', fontColour: 'mtn-orange' })
      scrollToHash(ScrollHash.email)
      return false
    }
    return true
  }

  const submitForm = async (): Promise<void> => {
    setEmailError(null)
    if (validEmail()) {
      const token = await recaptchaV3.getToken()
      setValue('recaptchaToken', token)
      setValue('recaptchaPage', MTNPaymentPage)
      submit(form.getValues())
    }
  }

  const renderEmail = (): ReactElement => {
    return <section id={ScrollHash.email} >
        <div className='py-6 px-4 flex flex-col rounded-lg bg-white border-mtn-gray-250 border-1'>
        <h2 className="text-lg font-bold text-mtn-blue-900">Contact email</h2>

        <form className="flex flex-col items-start mt-4">
          <Input
            autoComplete='email'
            type='email'
            id="email address"
            data-test-id={MytennightsTestId.emailInput}
            className={`w-full text-md rounded-md p-2 py-1 ${emailError ? `border-${emailError.colour} !border-2` : 'border-sz-gray-400'}`}
            value={user?.email ?? plan.donorDetails.email}
            onChange={(e) => {
              setEmail(e.target.value); setPlan({ ...plan, donorDetails: { ...plan.donorDetails, email: e.target.value } })
              form.setValue('email', e.target.value)
            }}
            onBlur={() => {
              MTNtracking.checkOutStarted(user?.email ?? plan.donorDetails.email, plan)
            }}
            placeholder={'Enter your email'} variant={'mtn'} />
        </form>
        {emailError && <p className={`text-sm font-bold mt-2 text-${emailError.fontColour}`}>{emailError.message}</p>}

        {user !== null && <p className="mt-2 text-xs">
          If you wish to use another email address please <Link
            className="underline text-mtn-blue" to="#" onClick={() => {
              logout()
            }}>log out</Link> first
        </p>}
        <div className='flex flex-row mt-2'>
          <p className='text-sm font-light text-mtn-blue-900'> We'll email you nightly receipts</p>
        </div>
        <label className="flex mt-4">
            <Checkbox
              isChecked={plan.emailOptIn}
              dataTestId={MytennightsTestId.marketingOptIn_ + PaymentFormProps.emailOptIn}
              toggleCheck={() => {
                const newEmailOptIn = !plan.emailOptIn
                form.setValue('emailOptIn', newEmailOptIn)
                setPlan({ ...plan, emailOptIn: newEmailOptIn })
              }}
            />
            <p className='text-sm text-mtn-blue-900'><b>Keep me updated!</b> I'd like emails about MyTenNights' projects, causes and news.</p>
        </label>

        <label className="flex mt-4">
            <Checkbox
              isChecked={plan.charityEmailOptIn}
              data-test-id={MytennightsTestId.marketingOptIn_ + PaymentFormProps.charityEmailOptIn}
              toggleCheck={() => {
                const newCharityEmailOptIn = !plan.charityEmailOptIn
                form.setValue('charityEmailOptIn', newCharityEmailOptIn)
                setPlan({ ...plan, charityEmailOptIn: newCharityEmailOptIn })
              }}
            />
            <p className='text-sm text-mtn-blue-900'>
              I agree to receive communications from the charity and allow my contact details to be shared with them.
            </p>
        </label>
      </div>
    </section>
  }

  const renderConfirmPaymentButton = (): ReactElement => {
    return <Button
        className="w-full font-bold bg-gm-yellow text-xl text-mtn-blue-800 disabled:opacity-30 disabled:text-mtn-blue-800"
        data-test-id={MytennightsTestId.submitButton}
        variant="mtn-donate-now"
        disabled={disableConfirmationButton}
        loading={isLoading}>
        DONATE
      </Button>
  }

  const renderGiftAid = (): ReactElement => {
    const dafName = plan.charity.charity_id === MultiCharity.gb ? 'Compassion for Creation' : 'Mercy Mission'

    const handleGiftAidClick = (): void => {
      const newValue = !plan.paymentDetails.giftAid
      setPlan({ ...plan, paymentDetails: { ...plan.paymentDetails, giftAid: newValue } })
      form.setValue('giftAid', newValue)
    }

    return <section className='mt-5'>
      <div className='py-6 px-4 flex flex-col rounded-lg bg-white border-mtn-gray-250 border-1'>
        <img className="w-28" src={giftAidLogo} alt="giftaid" />
        <label className="flex mt-4 items-center">

        <Checkbox
        isChecked={plan.paymentDetails.giftAid}
        dataTestId={MytennightsTestId.marketingOptIn_ + PaymentFormProps.emailOptIn}
        toggleCheck={() => handleGiftAidClick()}
        />
          <p className='font-bold text-sm text-mtn-blue-900' onClick={() => isMobile && handleGiftAidClick()}>Yes, I'd like to include GiftAid</p>
        </label>
        <p className="text-sm mt-4 text-mtn-blue-900">
        By selecting yes, I declare that I am UK taxpayer. <span className="font-bold text-fg-blue-500 cursor-pointer hover:underline" onClick={() => setShowMoreGiftAid(!showMoreGiftAid)}>Read more</span>
        </p>
      </div>
      <Modal isOpen={showMoreGiftAid} onClose={() => setShowMoreGiftAid(false)} backgroundColor='bg-mtn-blue-250'>
        <div className='px-10 pb-10 pt-2 max-h-96 md:max-h-full overflow-auto text-white flex flex-col'>
          <img className="w-28" src={giftAidWhiteLogo} alt="giftaid" />
          <p className='pt-6 text-base'>By selecting yes, I declare that I am a UK taxpayer.</p>
          <p className='pt-4 text-sm'>I understand that if I pay less Income Tax and/or Capital Gains Tax in the current tax year than the amount of Gift Aid claimed on my donations I must pay the difference. I am happy for {dafName} to claim Gift Aid on this donation and on prior donations I have made in the past to {dafName} where applicable. {dafName} reserves the right to use third party service providers to collect gift aid on its behalf. Where necessary, your personal data may be shared with your chosen charity or our third-party providers in order to process your gift aid. For more information, please see our <a href="/privacy-policy" target="_blank" className="font-bold">Privacy Policy.</a></p>
          <p className='pt-4 text-sm'>Where {dafName} deems it appropriate, {dafName} may use all or parts of this GiftAid to cover costs from 3rd party partners, including but not limited to fundraisers, donor advised fund fees and/or other third party providers who help facilitate or promote donations. This helps keep costs lower for charities, since the costs may be taken from the gift aid and thereby maintaining a platform for your chosen charity and therefore furthers {dafName}’s charitable objects. If you do not agree with this, you should not select yes.</p>
          <Button className='mt-6 bg-opacity-0 border-1 border-mtn-white' variant="mtn-primary" onClick={() => setShowMoreGiftAid(false)}>CLOSE</Button>
        </div>
      </Modal>
    </section>
  }

  const renderDisclaimer = (): ReactElement => {
    return <section className='mt-5 text-mtn-blue-900 font-normal text-xs gap-3 flex flex-col'>
      <p>
        By continuing, you agree with the MyTenNights <Link className="underline"
          to="/terms-and-conditions">terms</Link> and <Link
            className="underline " to="/privacy-policy">privacy policy</Link>.
        {!isMultiCharity(plan.charity.charity_id) && <>You also agree with {plan.charity.charity_name}&apos;s <a
          className="underline" href={plan.charity.privacy_policy_url}
          target="_blank" rel="noreferrer noopener">privacy policy</a>.</>}
      </p>
      <p>
        You also authorise MyTenNights to send instructions to the financial institution that issued
        your card to take payments from your card account in
        accordance with the agreement with you.
      </p>
      <p>
      This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" className="underline">Privacy Policy</a> and <a href="https://policies.google.com/terms" className="underline">Terms of Service</a> apply.
      </p>
    </section>
  }

  return <WithFooter backgroundColour='mtn-gray-900'>
    <Header />
    <CheckoutProgress step={Pages.Checkout} />
    <div className='bg-mtn-gray-900 pt-2'>
    {!isMobile && <h1 className="text-xl text-center text-mtn-blue-900 font-semibold">Review and pay</h1>}
    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
    <form onSubmit={handleSubmit(async () => submitForm())} className="flex w-full justify-center gap-x-6 p-4">
          <div className="max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-x-6">
            <div>
              {renderEmail()}
              {plan.charity.country === Country.GB && renderGiftAid()}
              <PaymentDetails form={form} variant={Variant.mtn} tracking={MTNtracking} paymentLoading={isLoading} submitForm={submitForm} validationError={validationError} validate={validEmail} disableConfirmPaymentButton={true} />
              <div className="hidden md:block">
                {renderDisclaimer()}
              </div>
            </div>
            <div className='mt-5 md:mt-0'>
              <CheckoutSummary renderActionButton={renderConfirmPaymentButton} showDonationBreakdown showDonationCauses/>
              <AdditionalContribution plan={plan} isEG={plan.wakfOptIn === 'yes'} isFG={plan.fridayGivingOptIn === 'yes'} />
              <div className="block md:hidden">
                {renderDisclaimer()}
              </div>
            </div>
          </div>
    </form>
    {renderPaymentErrorModal()}
    </div>
  </WithFooter>
}
