import { type ChangeEvent, type FC, type ReactElement, useEffect, useState } from 'react'
import { CharityCard } from '~/components/CharityCard'
import { type CharityCampaign, MTNtracking, useResponsive } from '@shamaazi/mytennights'
import { Country, getUserCountry, isMultiCharity } from '@lib/services'
import { useAuth } from '@lib/hooks'
import { ComponentsTestId, MytennightsTestId } from '@lib/testing'
import { Button, CountrySelection, Input, Page, SearchIcon } from '@lib/components'
import { WithFooter } from './footer'
import { useCheckoutPlan } from '@shamaazi/mytennights'
import { CauseCard } from './CauseCard'
import arrowRight from '~/img/arrow-right.svg'
import { useNavigate } from 'react-router-dom'
import { Navigation } from './navigation'

export const HomePageCharities: FC<{ onSelect: (charity: CharityCampaign) => void, charities: CharityCampaign[], partner?: string }> = ({ onSelect, charities, partner }) => {
  const countries = [...new Set(charities?.map(c => c.country))]
  const [country, setCountry] = useState(getUserCountry())
  const [searchState, setSearchState] = useState('')
  const { isMobile } = useResponsive()
  const { user } = useAuth()
  const { plan, setPlan } = useCheckoutPlan()

  const TabType = {
    Cause: 'cause',
    Charity: 'charity'
  } as const

  type TabTypeValue = typeof TabType[keyof typeof TabType]

  // Setting the default tab as Cause for non-UK and Charity for UK as per request from business
  const [activeTab, setActiveTab] = useState<TabTypeValue>(Country.GB !== getUserCountry() ? 'cause' as TabTypeValue : 'charity' as TabTypeValue)
  const navigate = useNavigate()
  const onSearchTextChanged = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchState(e.target.value)
  }

  // Reset cause amounts for multicharity as this appears for both causes and charity selection.
  useEffect(() => {
    setPlan({
      ...plan,
      ...(isMultiCharity(plan.charity.charity_id) && { causes: {} }),
      ...(plan.selectedCauses == null && { selectedCauses: [] }) // initializing the causes array if its null or undefined
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterCharitiesBySearchTerm = (charity: CharityCampaign): boolean => {
    const searchTerm = searchState.trim().toLowerCase()
    return charity.charity_name.toLowerCase().includes(searchTerm) || charity.charity_id.toLowerCase().includes(searchTerm) ||
      charity.causes?.find(c => c.title.toLowerCase().includes(searchTerm)) !== undefined
  }
  const filterForPartner = (charity: CharityCampaign): boolean => {
    if (partner) {
      return charity.partner.split(',').includes(partner)
    }
    return true
  }
  const charitiesFromUK: string[] = [
    'orphans-in-need-uk',
    'muslim-aid-uk',
    'islamic-relief-uk'
  ]

  const exclusionsByCountry: { [key in Country]?: string[] } = {
    [Country.CA]: ['islamic-relief-uk']
  }

  const pennyAppealUkCharityId: string = 'penny-appeal-uk'
  // We are adding some big charity names from the UK into the American, Canadian and Australian homepage to boost credibility in those countries
  const filterUKCharityIntoOtherCountries = (): CharityCampaign[] => {
    if (country === Country.US || country === Country.AU || country === Country.CA) {
      // Filter based on UK charities and apply any exclusions for the current country
      const exclusions = exclusionsByCountry[country] ?? []
      return charities.filter(c => charitiesFromUK.includes(c.charity_id) && !exclusions.includes(c.charity_id))
    }
    return []
  }

  const filterOutPennyAppealUk = (charity: CharityCampaign): boolean => {
    return !charity.charity_id.toLowerCase().includes(pennyAppealUkCharityId)
  }

  const pennyAppealUkCharityCampaign = (withPennyAppeal: boolean): CharityCampaign[] => {
    return withPennyAppeal && country === Country.GB ? charities.filter(c => pennyAppealUkCharityId === c.charity_id) : []
  }

  const renderCharityCards = (pennyAppealUk: boolean): ReactElement => {
    const filteredCharities = charities?.filter((c) => c.country === country).concat(...filterUKCharityIntoOtherCountries()).filter(filterOutPennyAppealUk).concat(pennyAppealUkCharityCampaign(pennyAppealUk)).filter(filterCharitiesBySearchTerm).filter(filterForPartner)
    return <>
      {filteredCharities.map((c) => {
        return <CharityCard key={c.charity_id} onSelect={() => onSelect(c)} charity={c} />
      })}
    </>
  }
  const renderCauseCards = (): ReactElement => {
    const filteredCharities = charities?.filter((c) => c.country === country && isMultiCharity(c.charity_id))
    return <>
      {filteredCharities.map((c) => (
        <CauseCard key={c.charity_id} charity={c} causes={c.causes} />
      ))}
    </>
  }
  const renderTab = (tabName: TabTypeValue, index: number): ReactElement => {
    return <>
      {<button
        key={tabName}
        className={`flex-1 h-11 py-2.5 px-5 rounded-3xl flex items-center justify-center ${index > 0 ? '-ml-7' : ''} ${activeTab === tabName
          ? 'bg-mtn-blue text-white font-bold z-20'
          : 'text-mtn-blue-800 font-bold'
          }`}
        onClick={() => setActiveTab(tabName)}
      >
        {tabName.charAt(0).toUpperCase() + tabName.slice(1)}{tabName === TabType.Cause && <span className='font-semibold mx-2 bg-mtn-yellow p-1 text-xs rounded text-mtn-blue-250'>NEW</span>}
      </button>}
    </>
  }
  const flag = <img alt="Flag" width={24} height={24} className="absolute pointer-events-none top-2/4 left-3 transform -translate-y-1/2 h-7 w-7" src={`/assets/flags/${country}.svg`} />
  const renderContinueBtn = (): ReactElement => {
    return <div className='flex flex-col w-full justify-center items-center'>
      <Button
        data-test-id={MytennightsTestId.continueButton}
        variant="mtn-donate-now"
        className='bg-gm-yellow button-disabled-color text-mtn-blue-800 disabled:text-mtn-blue-800 text-2xl py-3 w-72 uppercase font-bold '
        disabled={plan.selectedCauses.length === 0 || plan.selectedCauses.length > 4}
        onClick={() => {
          setPlan({ ...plan, causes: {} })
          navigate(Navigation.charityCauses())
          MTNtracking.confirmMCPCausesSelected(user?.email, plan)
        }}
      >
        <div className='flex gap-x-4 justify-center'>
          Continue <img src={arrowRight} alt='continue' />
        </div>
      </Button>
    </div>
  }

  return <WithFooter>
    <Page id="donate" className="md:mb-10 justify-center" data-test-id={ComponentsTestId.charitySelectionPage}>
      <div className="md:flex md:justify-center">
        <div className="flex flex-col items-center">
          <div className='px-4 text-center w-full flex flex-col items-center'>
            {!isMobile && <h1 className="text-xl font-semibold text-mtn-blue-800">Where would you like your donations to go?</h1>}
            <div className="my-5 w-full md:w-80">
              <div className="flex bg-mtn-gray-350 rounded-full">
                {[TabType.Cause, TabType.Charity].map((tab, index) => renderTab(tab as TabTypeValue, index))}
              </div>
            </div>
            <div className="w-full md:w-80">
              <CountrySelection
                countries={countries}
                value={country}
                className={`w-full max-w-full rounded-lg pl-12 ${activeTab === TabType.Cause ? '' : ''}`}
                onCountryChange={(v: Country) => {
                  setCountry(v)
                  setPlan({ ...plan, selectedCauses: [] })
                  MTNtracking.countryChange(user?.email, v, MTNtracking.getNightsLengthChoice(plan.totalNight.value), plan.split)
                }}
                FGFlag={flag}
                variant="mtn"
              />
              {activeTab !== TabType.Cause && <div className="my-4 relative w-full">
                <SearchIcon />
                <Input variant="mtn" value={searchState} onChange={onSearchTextChanged}
                  placeholder="Find a charity or cause" className="w-full max-w-full rounded-lg pl-12" />
              </div>}
            </div>
            <p className="text-sm mt-2 text-fg-gray font-medium">{`All donations are tax deductible${country === Country.US ? ' when giving to charities located in the region you are based only' : ''}. ${activeTab === TabType.Cause ? 'Select up to 4 causes.' : ''}`}</p>
          </div>
        </div>
      </div>
      {activeTab === TabType.Charity && <div className="flex justify-between my-5 md:mx-36">
        <div className="w-full mx-4 md:mx-10 grid grid-cols-1 gap-4 place-content-center lg:grid-cols-3 md:grid-cols-2 sm:gap-4">
          {renderCharityCards(true)}
        </div>
      </div>}
      {activeTab === TabType.Cause && <div className="flex flex-col justify-between items-center my-5 mx-5 md:mx-48">
        <div className="gap-4 w-full mb-7">
          {renderCauseCards()}
        </div>
        {renderContinueBtn()}
      </div>}
    </Page>
  </WithFooter>
}
