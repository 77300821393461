import { Button } from '@lib/components'
import { useAuth } from '@lib/hooks'
import { MytennightsTestId } from '@lib/testing'
import {
  MTNtracking,
  ScrollHash,
  scrollToHash,
  isMobileScreen,
  CampaignSetup,
  type NightType,
  SplitType,
  mtnSplitTexts,
  useCheckoutPlan,
  NightTypeOpt
} from '@shamaazi/mytennights'
import { DateTime } from 'luxon'
import moment from 'moment'
import { type FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import arrowRight from '~/img/arrow-right.svg'
import { Navigation } from '~/components'
import { HomePageCharityImages } from '~/components/HomePageCharityImages'

export const GetDiffBetweenCampaignDate = (): number => {
  const { plan } = useCheckoutPlan()
  const ramadanStartDate = moment(CampaignSetup.defaultRamadanStartDate.toString()) // fetch this ramamdan start date from some configs
  const currentDate = moment(DateTime.now().setZone(plan.timezone))
  return ramadanStartDate.diff(currentDate, 'd')
}
export const Split: FC<{ nextPage: string, onClick?: () => void, isCharity?: boolean, disableCharityLogos?: boolean }> = ({ nextPage, onClick, isCharity = false, disableCharityLogos = false }) => {
  const [isMobile, setIsMobile] = useState(isMobileScreen())
  useEffect(() => {
    window.addEventListener('resize', () => setIsMobile(isMobileScreen()))
    return window.removeEventListener('resize', () => setIsMobile(isMobileScreen()))
  })
  const { plan, setPlan } = useCheckoutPlan()
  const { user } = useAuth()
  const splitTypes = [SplitType.Equally, SplitType.MoreOdd, SplitType.MoreLastTenOddNights, SplitType.More27]
  const diff = GetDiffBetweenCampaignDate()
  const updateState = (): void => {
    setPlan({
      ...plan,
      firstNight: lengthTypeSelected.key === NightTypeOpt.LastTenAvailable ? 20 : 30 - lengthTypeSelected.value,
      split: splitTypeSelected,
      totalNight: lengthTypeSelected
    })
    const partner = MTNtracking.getPartner().partner
    onClick ? onClick() : navigate(Navigation.prefixPartnerApplicableRoute(nextPage, partner))
    MTNtracking.confirmSplitDonation(user?.email, plan)
  }

  const isMoreThanTenDays = diff > -19
  const lengthTypes: NightType[] = [{
    key: NightTypeOpt.AllAvailable,
    value: diff > 0 ? 29 : 29 + diff // if ramadan is still in the future, we just set 29, if ramadan started already, we just subtract the days already done in ramadan.
  }]
  // If we have more than 10 days left in ramadan, we had this option
  if (isMoreThanTenDays) {
    lengthTypes.unshift({
      key: NightTypeOpt.LastTenAvailable,
      value: 10
    })
  }
  const [splitTypeSelected, setSplitTypeSelected] = useState<SplitType>(plan?.split ? plan.split : SplitType.Equally) // if it has an onCLick, it means its a modal and the user already set their choice so retrieve the users choice, else use the default
  const [lengthTypeSelected, setLengthTypeSelected] = useState<NightType>(plan.totalNight ?? { key: '', value: 0 })
  const navigate = useNavigate()
  const splitTexts = mtnSplitTexts
  const [availableSplitTypes, setAvailableSplitTypes] = useState<SplitType[]>()
  const updateSplitTypes = (): void => {
    let currentSplitTypes: SplitType[] = []
    let resetSplitType = false
    if (lengthTypeSelected.key === NightTypeOpt.AllAvailable) {
      currentSplitTypes = splitTypes.filter(type => type !== SplitType.MoreOdd)
      resetSplitType = splitTypeSelected === SplitType.MoreOdd // we will reset the split type if the user select the opposite night type
    } else { // by default we always show last 10 nights option
      currentSplitTypes = splitTypes.filter(type => type !== SplitType.MoreLastTenOddNights)
      resetSplitType = splitTypeSelected === SplitType.MoreLastTenOddNights
    }
    setAvailableSplitTypes(currentSplitTypes)
    if (resetSplitType) {
      setSplitTypeSelected(SplitType.Equally)
    }
  }
  useEffect(() => {
    updateSplitTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lengthTypeSelected])
  useEffect(() => {
    if (!isMoreThanTenDays) { // auto select the first item if in the ladt 10 days
      setLengthTypeSelected(lengthTypes[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMoreThanTenDays])

  return <div className={`px-6 ${!disableCharityLogos ? 'sm:px-20' : ''} flex flex-col justify-center items-center ${onClick ? 'gap-y-4' : 'gap-y-8'}`}>
    {isMoreThanTenDays && <div className=" gap-y-4 flex flex-col items-center" id={ScrollHash.length}>
      <p className={`text-mtn-white text-sm ${!disableCharityLogos ? 'mt-14' : ''}`}> CHOOSE THE LENGTH</p>
      <div className={`flex flex-col gap-y-4 ${onClick ? '' : 'md:flex-row md:gap-x-4'}`}>
        {lengthTypes.map(lengthType => {
          const lengthIsChosen = lengthTypeSelected.key === lengthType.key
          return (
            <Button key={lengthType.key}
              className="py-3 text-xl md:text-2xl"
              data-test-id={MytennightsTestId.donationSplitButton_ + lengthType.key}
              variant={lengthIsChosen ? 'mtn-primary-beta-inverted' : 'mtn-primary-beta'}
              onClick={() => {
                setLengthTypeSelected(lengthType)
                if (isMobile) {
                  scrollToHash(ScrollHash.chooseSplit)
                }
              }}>{lengthType.value > 10 ? (
                <>{`ALL NIGHTS`}<span className='align-top mx-2 bg-gm-green p-1 text-xs rounded text-white'>NEW</span></>
              ) : (
                `${lengthType.value} NIGHTS`
              )}</Button>
          )
        })}
      </div>
    </div>}
    <div id={ScrollHash.chooseSplit} className={`${!isMoreThanTenDays || onClick ? '' : 'mt-80 md:my-5'}`}>
    <div className={`${!isMoreThanTenDays || onClick ? 'gap-y-4 flex mt-0 flex-col items-center' : 'gap-y-4 mt-36 md:mt-0 flex flex-col items-center'}`}>
      <p className="text-mtn-white text-sm mt-4"> CHOOSE THE SPLIT</p>
      <div className={`flex flex-col gap-y-4 ${onClick ? '' : 'md:flex-row md:gap-x-4'}`}>
        {availableSplitTypes?.map(splitType => {
          const splitIsChosen = splitTypeSelected === splitType
          return (
            <Button key={splitType}
              className="md:px-4"
              data-test-id={MytennightsTestId.donationSplitButton_ + splitType}
              variant={splitIsChosen ? 'mtn-primary-beta-inverted' : 'mtn-primary-beta'}
              onClick={() => {
                setSplitTypeSelected(splitType)
                if (isMobile) {
                  scrollToHash(ScrollHash.continueSetup)
                }
              }}> {splitTexts[splitType]?.toUpperCase()}</Button>
          )
        })}
      </div>
    </div>
    <div id={ScrollHash.continueSetup} className={`${onClick ? 'py-0 mt-10' : 'py-4 mt-20 mb-24 md:mt-10 md:mb-0'} gap-y-4 flex flex-col items-center`}>
      {!disableCharityLogos && <p className="text-mtn-white text-xs"> You're setting up <span className="font-bold"> {lengthTypeSelected.value } nights </span> - {splitTexts[splitTypeSelected]}</p>}
      <div className="flex md:flex-row md:gap-x-4 flex-col gap-y-4">
      {disableCharityLogos &&
      <div className="mt-6 mb-10 flex justify-center gap-6">
        <Button
          data-test-id={MytennightsTestId.continueButton}
          variant="mtn-donate-now"
          className='bg-mtn-blue-250 border-2 border-white text-2xl py-3 w-32 uppercase font-bold '
          onClick={() => {
            onClick && onClick()
          }}
        >
        <div className='flex gap-x-4 justify-center text-white'>
          Close
        </div>
        </Button>
        <Button data-test-id={MytennightsTestId.continueButton} disabled={lengthTypeSelected.key === '' || splitTypeSelected === SplitType.NotSelected} variant="gm-share" className={`button-disabled-color rounded-full ${onClick ? 'py-0' : 'py-3'} ${disableCharityLogos ? 'w-28' : ''} px-5 md:px-3 text-lg md:text-2xl active:bg-gm-yellow`} onClick={() => updateState()}>
              <span>SAVE</span>
        </Button>
      </div>}
        {!disableCharityLogos &&
        <Button data-test-id={MytennightsTestId.continueButton} disabled={lengthTypeSelected.key === '' || splitTypeSelected === SplitType.NotSelected} variant="gm-share" className={`button-disabled-color rounded-full ${onClick ? 'py-0' : 'py-3'} ${disableCharityLogos ? 'w-28' : ''} px-5 md:px-3 text-lg md:text-2xl active:bg-gm-yellow`} onClick={() => updateState()}>
          <div className="flex gap-x-2">
          { isCharity ? 'CONTINUE' : 'DONATE'} <img alt="select charity" src={arrowRight} />
          </div>
        </Button>}
      </div>
    </div>
    {!disableCharityLogos && <HomePageCharityImages />}
    {!onClick && <>
    <div className='flex flex-col mb-20 md:flex-row items-center lg:flex-row md:self-stretch lg:self-stretch md:justify-around lg:justify-around md:items-baseline lg:items-start'>
      <div className='flex flex-col items-center text-white mb-10'>
        <p className='text-2xl text-center font-bold tracking-wide md:text-3xl'>Over $100m</p>
        <p className='text-base md:text-lg lg:text-xl'>raised through</p>
        <p className='text-base md:text-lg lg:text-xl'>MyTenNights</p>
      </div>
      <div className='flex my-10 lg:my-0 flex-col items-center text-white'>
        <p className='text-2xl text-center font-bold tracking-wide md:text-3xl'>Over 200k</p>
        <p className='text-base md:text-lg lg:text-xl'>donors have automated</p>
        <p className='text-base md:text-lg lg:text-xl'>with MyTenNights</p>
      </div>
    </div>
    </>}
    </div>
  </div>
}
