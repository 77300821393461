import { type FC } from 'react'

interface AdditionalContributionCardProps {
  image?: string
  background?: string
  amount?: string
  paymentDate?: string
  imageClasses?: string
}

const AdditionalContributionCard: FC<AdditionalContributionCardProps> = ({ image, background, amount, paymentDate, imageClasses }) => {
  return (
                <>
                        <div style={{ background }}
                                className="m-1 w-full px-4 pt-3 pb-4 rounded-md border border-white/10 flex-col justify-end items-end inline-flex">
                                <div className="self-stretch justify-between items-center inline-flex">
                                        <div className="h-4 justify-center items-start flex">
                                                <div className="h-4 relative flex-col justify-start items-start flex">
                                                        <img className={imageClasses} alt="" src={image} />
                                                </div>
                                        </div>
                                        <div className="text-white text-sm font-bold tracking-tight">{amount}</div>
                                </div>
                                <div className="self-stretch text-right"><span className="text-white text-xs font-normal tracking-tight">First payment </span>
                                        <span className="text-white text-xs font-semibold tracking-tight">{paymentDate}</span></div>
                        </div>
                </>
  )
}

export default AdditionalContributionCard
