import { Button } from '@lib/components'
import {
  useCheckoutPlan,
  getSplitBreakdown,
  scrollToHash,
  ScrollHash,
  MTNtracking,
  Ramadan,
  MessageBox,
  ActiveExperiments,
  EverGiveContentVariants
} from '@shamaazi/mytennights'
import { type FC, useState, type ReactElement, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { CheckoutProgress, Navigation, Pages, WithFooter } from '~/components'
import { MytennightsTestId } from '@lib/testing'
import { useAuth } from '@lib/hooks'
import { Header } from '~/components/header'
import arrowRight from '~/img/arrow-right.svg'
import CurrencyInput from 'react-currency-input-field'
import { Choice, GetCurrencyByString, getCurrencySymbol } from '@lib/services'
import Flair from '~/components/Flair'
import MFIcon from '~/img/mf-icon.png'
import egLogo from '~/img/EG logo.svg'
import EGSectionImage from '../img/fchcNp.svg'
import { CheckoutSummary } from '~/components/CheckoutSummary'
import { Experiment, Variant as SplitTestVariant } from '@lib/react-ab-test'

export const Details: FC<{ nextPage: string }> = ({ nextPage }) => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { plan, setPlan } = useCheckoutPlan()
  const [fridayGiver, setFridayGiver] = useState(plan.fridayGivingOptIn)
  const [fridayGivingAmount, setFridayGivingAmount] = useState(plan.fridayGivingOptIn === Choice.yes ? plan.fridayGiving : 1000)
  const [wakf, setWakf] = useState(plan.wakfOptIn)
  const [wakfAmount, setWakfAmount] = useState(plan.wakfOptIn === Choice.yes ? plan.wakf : 2000)
  const [wakfError, setWakfError] = useState('')
  const [fGError, setFGError] = useState('')
  const breakdown = getSplitBreakdown(plan)
  const disableContinueFG = plan.fridayGivingOptIn === '' || (plan.fridayGivingOptIn === Choice.yes && plan.fridayGiving === 0)
  const disableContinueWakf = plan.wakfOptIn === '' || (plan.wakfOptIn === Choice.yes && plan.wakf === 0)

  // setting initial key in order to unmount the component when the "No" choice is selected and mount it again on "Yes" Choice
  const [fridayGivingInputKey, setFridayGivingInputKey] = useState(0)
  const [wakfInputKey, setWakfInputKey] = useState(0)
  const [page, setPage] = useState(1)
  const fridayGiving = 'fridayGiving'
  const everGive = 'everGive'
  const currentBox = (() => {
    if (page === 1) {
      return fridayGiving
    } else {
      return everGive
    }
  })()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [page])

  const handleValueChange = (value: string | undefined, setError: (msg: string) => void, setAmount: (amount: number) => void, field: 'wakf' | 'fridayGiving'): void => {
    const valueInNumber = isNaN(Number(value)) ? 0 : Number(value)
    if (valueInNumber === 0) {
      setError('Please enter an amount to continue')
    } else {
      setError('')
    }
    // rounding the value due to floating-point precision errors in JavaScript
    const rountedValue = Math.round(valueInNumber * 100)
    setPlan({ ...plan, [field]: rountedValue })
    setAmount(rountedValue)
  }

  const InputPerDateText = ({ text }: { text: string }): JSX.Element => {
    return (
      <span className="w-1/2 flex justify-end items-center px-4 text-xs border-sz-gray-400 focus:outline-none">
        {text}
      </span>
    )
  }

  const renderContinueBtn = (): JSX.Element => {
    const isShowingFridayGiving = currentBox === fridayGiving
    const isDisabled = isShowingFridayGiving ? disableContinueFG : disableContinueWakf

    return <Button
      data-test-id={MytennightsTestId.continueButton}
      variant="mtn-donate-now"
      className={`${isDisabled ? 'button-disabled-color' : 'bg-gm-yellow'} text-mtn-blue-800 disabled:text-mtn-blue-800 text-2xl py-3 w-64 uppercase font-bold `}
      disabled={isDisabled}
      onClick={() => {
        let validationError = false
        if (isShowingFridayGiving) {
          if (!plan.fridayGivingOptIn) {
            setFGError('Please let us know if you would be interested in Friday Giving')
            scrollToHash(ScrollHash.fridayGiving)
            validationError = true
          }
          if (plan.fridayGivingOptIn === Choice.yes && fridayGivingAmount < 100) {
            setFGError('Please enter a valid amount for your Friday Giving')
            scrollToHash(ScrollHash.fridayGiving)
            validationError = true
          }
          if (validationError) {
            return
          }
          setFGError('')
          const firstNight = breakdown.nights.find((n) => n.isOpen)
          setPlan({ ...plan, firstNight: firstNight?.number ?? Ramadan.firstDonationNight, fridayGiving: plan.fridayGivingOptIn === Choice.yes ? fridayGivingAmount : 0 })
          MTNtracking.confirmMyFridays(user?.email, plan, fridayGivingAmount)
        } else {
          if (!plan.wakfOptIn) {
            setWakfError('Please let us know if you would be interested in Evergive')
            scrollToHash(ScrollHash.wakf)
            validationError = true
          }
          if (plan.wakfOptIn === Choice.yes && wakfAmount < 100) {
            setWakfError('Please enter a valid amount to continue')
            scrollToHash(ScrollHash.wakf)
            validationError = true
          }
          if (validationError) {
            return
          }
          setWakfError('')
          setPlan({ ...plan, wakf: plan.wakfOptIn === Choice.yes ? wakfAmount : 0 })
          MTNtracking.confirmEverGive(user?.email, plan, wakfAmount, fridayGivingAmount)
        }
        if (page === 1) {
          setPage(2)
        } else {
          const partner = MTNtracking.getPartner().partner
          navigate(Navigation.prefixPartnerApplicableRoute(nextPage, partner))
        }
      }}
    >
      <div className='flex gap-x-4 justify-center' >
        Continue<img src={arrowRight} alt='continue' />
      </div>
    </Button>
  }
  const renderFridayGivingBoxVersion = (): ReactElement => {
    return <section id={ScrollHash.fridayGiving} className={`friday-giving-dark-bg-image flex flex-col p-6 rounded-2xl bg-cover bg-no-repeat bg-center`}>
      <div className='flex flex-row justify-between relative'>
        <div className='flex flex-col'>
          <img src={MFIcon} alt="myfridays" className="h-4 w-36" />
          <h2 className={`text-white text-xl font-semibold mt-3`}> Become a Friday Giver </h2>
        </div>
        <MessageBox>
          <p> Your donations to Friday Giving will go into the MyTenNights Fund.</p>
          <br /> <p>Tips will be applied to this subscription.</p>
          <br />
          <p>We will copy the percentage you selected for your MyTenNights donation here for your Friday donation tip; this means your weekly donation will be debited with the same percentage tip as before. For example, if you choose to generously give us a 5% tip for your MyTenNights donation, we will also debit a 5% tip for your weekly Friday donation.</p>
          <br />
          <p>Your subscription payment value will automatically increase by 10% each year.</p>
        </MessageBox>
      </div>
      <div className='flex flex-row my-1'>
        <p className={`text-base text-white font-light mb-3`}> Automatically donate every Friday after Ramadan, so you <span className='font-semibold'>never miss the Hour of Acceptance!</span> Cancel anytime.</p>

      </div>

      <label className="flex items-center">
        <input
          data-test-id={MytennightsTestId.fridayGivingYesButton}
          type="radio"
          value="true"
          checked={fridayGiver === Choice.yes}
          className="mr-4 radioCheck-dark"
          onClick={() => {
            setFridayGiver(Choice.yes)
            setPlan({ ...plan, fridayGivingOptIn: Choice.yes, fridayGiving: fridayGivingAmount })
          }} />
        <div className="flex flex-row items-center my-1 gap-x-4">
          <p className={`text-white font-semibold mr-1`}>Yes</p>
          <div className='flex bg-mtn-gold text-mtn-blue-800 p-1 font-medium text-xs rounded items-center'>
            <span className='px-1'> POPULAR CHOICE </span>
            <Flair />
          </div>
        </div>
      </label>
      <div className={`transition-all duration-700 ease-in-out transform overflow-hidden 
    ${fridayGiver === Choice.yes ? 'opacity-100 scale-100 translate-y-0' : 'opacity-0 scale-95 translate-y-5 max-h-f absolute top-0 w-0 h-0'} font-medium col-span-1 py-1 px-5 `} >
        <div className={`flex flex-col gap-y-6 justify-between items-left h-full`}>
          <form className="ml-5 flex rounded-lg bg-white border-1 flex-row w-full">
            <span className="flex w-14 justify-center items-center pl-2 text-sm font-semibold text-right border-sz-gray-400 focus:outline-none">
              {getCurrencySymbol(GetCurrencyByString(plan.charity.currency))}
            </span>
            <CurrencyInput
              key={fridayGivingInputKey}
              allowNegativeValue={false}
              data-test-id={MytennightsTestId.fridayGivingInput}
              id="friday giving amount"
              className="w-5/6 text-xl rounded-lg font-bold p-2 py-1 focus:outline-none text-gm-black"
              placeholder={'0.00'}
              step={0.01}
              defaultValue={fridayGivingAmount / 100}
              onValueChange={(value) => handleValueChange(value, setFGError, setFridayGivingAmount, 'fridayGiving')}
            />
            <InputPerDateText text="every week" />
          </form>
          {fGError && <div className="mt-3 text-sm text-mtn-red">{fGError}</div>}
        </div>
      </div>
      <label className="flex items-center mt-7">
        <input
          data-test-id={MytennightsTestId.fridayGivingNoButton}
          type="radio"
          value="false"
          checked={fridayGiver === Choice.no}
          className="mr-4 radioCheck-dark"
          onClick={() => {
            setFridayGivingInputKey((prev) => prev + 1)
            setFridayGiver(Choice.no)
            setFGError('')
            setFridayGivingAmount(0)
            setPlan({ ...plan, fridayGivingOptIn: Choice.no, fridayGiving: 0 })
          }} />
        <div className="flex flex-col sm:flex-row my-1">
          <p className={`text-white font-semibold mr-1`}>No</p>
        </div>
      </label>
      <div className='w-auto mt-5 mb-3'>
        <span className={`text-white font-thin text-sm`}> We take your donation one hour before your Friday Maghreb. </span>
      </div>
      <div className='w-auto my-2'>
        <span className={`text-mtn-gold font-thin text-sm italic`}> <span className=' font-bold'>‘Give charity on Friday, there is an hour during which Allah will accept any supplication you make’</span><br /><br />~ Prophet Muhammed PBUH </span>
      </div>
    </section>
  }

  const renderWakfBox = (heading: string, content: string): ReactElement => {
    return <section id={ScrollHash.wakf}>
      <div className={`bg-eg-green-100 flex flex-col p-6 rounded-t-2xl bg-cover bg-no-repeat bg-right-bottom`}>
        <div className='flex flex-row justify-between relative'>
          <div className='flex flex-col my-1'>
            <img src={egLogo} alt={egLogo} className="h-3.5 w-24" />
            <h2 className="text-white text-xl font-semibold mt-3">
              {heading}
            </h2>
          </div>
          <MessageBox>
            <div className='text-xs flex flex-col gap-y-3'>
              <div>
                <p className='font-bold'>Can I follow how my donation is being invested?</p>
                <span className=' font-light'>Yes, transparency is a cornerstone of our operations. Donors can track their contributions and see the direct impact of their donations through regular updates and reports.</span>
              </div>
              <div>
                <p className='font-bold'>Where can I learn more?</p>
                <p className=' font-light'>We partner with Evergive (Givetree Ltd) to bring you this service. You can visit the <u><a target='_blank' href="https://evergive.com/" rel="noreferrer">EverGive website</a></u> to find out more about how it works.</p>
              </div>
              <div>
                <p className='font-bold'>Privacy Policy</p>
                <p className='font-light my-3'>By donating to EverGive, you agree to their Privacy Policy. Your data will be shared with EverGive for processing your donation, and you may receive transactional communications from our or their team.</p>
                <p className='font-light'>Your donations to EverGive will be treated in line with the MyTenNights Fund policy.</p>
              </div>
              <div>
                <p className='my-3 font-light'>Tips will be applied to this subscription.</p>
                <p className='font-light'>We will copy the percentage you selected for your MyTenNights donation here for your EverGive donation tip; this means your monthly
                  donation will be debited with the same percentage tip as that of your MyTenNights donation.
                  For example, if you choose to generously give us a 5% tip for your MyTenNights donation, we will also debit a 5% tip for your monthly EverGive donation.</p>
              </div>
              <div>
                <p>
                  Your subscription payment value will automatically increase by 10% each year.
                </p>
              </div>
            </div>
          </MessageBox>
        </div>
        <div className='flex flex-row my-1'>
          <p className={`text-base text-white font-light mb-3`}>
            {content}
          </p>
        </div>

        <label className="flex items-center">
          <input
            data-test-id={MytennightsTestId.wakfYesButton}
            type="radio"
            value="true"
            checked={wakf === Choice.yes}
            className="mr-4 radioCheck-dark"
            onClick={() => {
              setWakf(Choice.yes)
              setPlan({ ...plan, wakfOptIn: Choice.yes, wakf: wakfAmount })
            }} />
          <div className="flex flex-row items-center my-1 gap-x-4">
            <p className={`text-white font-semibold mr-1`}>Yes</p>
            {<div className='flex bg-mtn-gold-100 text-mtn-blue-250 p-1 font-medium text-xs rounded items-center'>
              <span className='px-1'> POPULAR CHOICE </span>
              <Flair color='#162146' />
            </div>}
          </div>
        </label>

        <div
          className={`transition-all duration-700 ease-in-out transform overflow-hidden 
        ${wakf === Choice.yes ? 'opacity-100 scale-100 translate-y-0' : 'opacity-0 scale-95 translate-y-5 max-h-f absolute top-0 w-0 h-0'} font-medium col-span-1 pb-5 pt-1 px-5 rounded-2xl`} >

          <div className="flex flex-col gap-y-6 justify-between items-left h-full">
            <form className="ml-5 flex rounded-lg bg-white border-1 flex-row w-full">
              <span className='flex w-14 justify-center items-center pl-2 text-sm text-right border-sz-gray-400 focus:outline-none'>{getCurrencySymbol(GetCurrencyByString(plan.charity.currency))}</span>
              <CurrencyInput
                key={wakfInputKey}
                allowNegativeValue={false}
                data-test-id={MytennightsTestId.wakfInput}
                id="friday giving amount"
                className="w-2/4 text-xl rounded-lg font-bold p-2 py-1 focus:outline-none text-gm-black"
                placeholder={'0.00'}
                step={0.01}
                defaultValue={wakfAmount / 100}
                onValueChange={(value) => handleValueChange(value, setWakfError, setWakfAmount, 'wakf')}
              />
              <InputPerDateText text="every month" />
            </form>
            {wakfError && <div className="mt-3 text-sm text-mtn-red">
              {wakfError}
            </div>}
          </div>
        </div>
        <label className="flex items-center mt-3">
          <input
            data-test-id={MytennightsTestId.wakfNoButton}
            type="radio"
            value="false"
            checked={wakf === Choice.no}
            className="mr-4 radioCheck-dark"
            onClick={() => {
              setWakfInputKey((prev) => prev + 1)
              setWakf(Choice.no)
              setWakfError('')
              setWakfAmount(0)
              setPlan({ ...plan, wakfOptIn: Choice.no, wakf: 0 })
            }} />
          <div className="flex flex-col sm:flex-row my-1">
            <p className={'text-white font-semibold mr-1'}>No</p>
          </div>
        </label>
        <div className='w-auto my-2'>
          <span className={`text-mtn-gold font-thin md:text-sm text-sm`}>
            <span className=' font-bold'>‘When a Muslim dies all their rewards stop, except for <br />their ongoing charity...’
              <br /> Prophet (PBUH)</span>
          </span>
        </div>
      </div>
      <div>
        <div className='h-40'>
          <img
            className="object-cover w-full relative md:bottom-24 bottom-16"
            src={EGSectionImage}
            alt=""
          />
        </div>
      </div>
    </section>
  }

  return (
    <WithFooter backgroundColour='mtn-gray-900'>
      <Header />
      <CheckoutProgress step={Pages.Extras} />
      <div className='bg-mtn-gray-900 pb-10'>
        <div className="justify-self-center text-mtn-blue-900 font-bold text-center py-5 hidden md:block text-xl">Make your good deeds go further</div>
        <div className='px-5 md:px-52 flex flex-col md:flex-row justify-center'>
          <div className={`font-semibold w-full md:w-3/5 flex flex-col gap-y-4 pt-3 mr-5`}>
            {currentBox === fridayGiving && renderFridayGivingBoxVersion()}
            {currentBox === everGive &&
              (
                <Experiment name={ActiveExperiments.EverGiveContentVariants} >
                  <SplitTestVariant name={EverGiveContentVariants.control}>{renderWakfBox('Make your rewards last forever', 'Automate a monthly donation. We invest it and the profits go to charity forever, so your rewards won`t stop after you die. Cancel anytime.')}</SplitTestVariant>
                  <SplitTestVariant name={EverGiveContentVariants.variant}>{renderWakfBox('Become an EverGiver, so your rewards last forever', 'We invest your monthly donations and give the profits to charity every month forever, so your rewards go on for thousands and thousands of years! Cancel anytime.')}</SplitTestVariant>
                </Experiment>
              )
            }
          </div>
          <div className="mt-3">
            <CheckoutSummary renderActionButton={renderContinueBtn} showDonationBreakdown showDonationCauses />
          </div>
        </div>
      </div>
    </WithFooter>
  )
}
