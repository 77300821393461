import { useResponsive } from '@shamaazi/mytennights'
import { type FC } from 'react'

export enum Pages {
  Select = 'Select',
  Split = 'Split',
  Extras = 'Extras',
  Checkout = 'Checkout',
}

export const CheckoutProgress: FC<{ step: Pages }> = ({ step }) => {
  const { isMobile } = useResponsive()

  const wizardDonationSplit = [Pages.Select, Pages.Split, Pages.Extras, Pages.Checkout]

  const getStepElement = (checkoutStep: Pages, progressStep: Pages, order: Pages[], index: number): JSX.Element => {
    const stepStyles = {
      completedStep: 'text-white bg-mtn-blue-300',
      currentStep: 'text-mtn-blue-300 ring-inset ring-4 ring-mtn-blue-300',
      upcomingStep: 'text-mtn-blue-300 progress-bar-bg'
    }

    const upcomingStep = order.indexOf(checkoutStep) < order.indexOf(progressStep)
    const currentStep = order.indexOf(checkoutStep) === order.indexOf(progressStep)

    const stepStyle = upcomingStep ? stepStyles.upcomingStep : currentStep ? stepStyles.currentStep : stepStyles.completedStep
    const afterLineStyle = upcomingStep || currentStep ? 'progress-bar-bg' : 'bg-mtn-blue-300'
    const beforeLineStyle = upcomingStep ? 'progress-bar-bg' : 'bg-mtn-blue-300'

    return <li key={progressStep} className={`flex flex-col gap-y-3 w-full justify-center items-center`}>
      <div className="flex items-center w-full justify-center">
        {/* Before line */}
        <div className={`h-1 ${index === 0 ? 'invisible' : ''} ${beforeLineStyle} flex-1`}/>

        {/* Step */}
        <div
          className={`md:w-8 md:h-8 w-6 h-6 text-sm sm:text-md font-bold rounded-full flex justify-center items-center ${stepStyle}`}>
          {!isMobile && `${order.indexOf(progressStep) + 1}`}
        </div>

        {/* After line */}
        <div className={`h-1 ${index === order.length - 1 ? 'invisible' : ''} ${afterLineStyle} flex-1`}/>

      </div>
      {!isMobile && <span className={`text-mtn-blue uppercase text-sm font-semibold`}> {progressStep}</span>}
    </li>
  }

  return <div className="flex justify-center bg-mtn-gray-900">
    <ol className={'grid sticky top-0 z-30 grid-cols-4 py-3 w-full md:w-1/2 lg:w-1/3 items-center font-medium sm:py-4'}>
        {Object.values(wizardDonationSplit).map((page, index) => getStepElement(step, page, wizardDonationSplit, index))}
      </ol>
  </div>
}
