import { MessageBox } from '@shamaazi/mytennights'
import { type FC, type ReactElement } from 'react'

interface EverGiveCauseWidgetProps {
  egCauses: ReactElement[]
}

export const EverGiveCauseWidget: FC<EverGiveCauseWidgetProps> = ({ egCauses }) => {
  return <section className="bg-white border border-mtn-gray-250 p-6 rounded-lg">
        <div className='text-fg-blue-800 flex flex-row relative justify-between mb-4'>
            <div className='flex flex-col'>
                <div className='flex flex-row justify-between w-full md:mb-0 relative items-baseline'>
                    <p className="text-mtn-blue-800 text-base font-bold">Add a donation that lasts forever</p>
                    <MessageBox infoIcon tooltipBorder>
                        <div>
                            <p className="font-light">
                                We’ve partnered with EverGive to ensure your donations create a lasting impact.
                                This will be an additional contribution that will be spread out across the nightly donation amounts.
                                When you contribute, your donations are invested, and the profits are shared to charitable causes in line with the MyTenNights Fund.
                            </p>
                            {
                                <p className="font-light mt-3">
                                    Learn more about EverGive's <a href='https://www.evergive.com/company/our-investment-approach' target='_blank' className='text-black font-bold' rel="noreferrer"> Investment Strategy. </a>
                                </p>}
                            <p className='font-light mt-3'>By opting in with an amount, you agree to EverGive’s
                                <a href='https://www.evergive.com/company/policies/privacy-policy' target='_blank' className='text-black font-bold' rel="noreferrer"> privacy policy </a>
                                and <a href='https://www.evergive.com/company/policies/terms-and-conditions' target='_blank' className='text-black font-bold' rel="noreferrer">terms and conditions</a>.</p>
                        </div>
                    </MessageBox>
                </div>
                <p className='text-sm text-left text-fg-blue-800 mt-3'>We invest a portion of your nightly donation and give
                    the profits to charity every month forever, so your rewards go on for thousands of years after you die.</p>
                <div className="flex flex-col gap-y-2 mt-3">
                    {egCauses}
                </div>
            </div>
        </div>
    </section>
}
