import { type FC } from 'react'
import { Header, WithFooter } from '~/components'
import { Page } from '@lib/components'

export const FundTermsAndConditions: FC = () => {
  return <WithFooter>
    <Header />
    <Page skinny className="px-10 my-16 font-medium terms-and-conditions">
      <section>
        <h1 className="my-8 text-4xl font-semibold text-mtn-blue-800">The MyTenNight Fund: Multiplying the Impact of Your Generosity</h1>
      </section>
      <section>
        <h2 className="my-4 text-2xl text-mtn-blue-800">Donors to the MyTenNight Fund see their impact multiply by up to 3X!</h2>
        <p className="mt-4">At the heart of the MyTenNight Fund is a mission to amplify the power of your donations, creating an ecosystem that extends their impact far beyond what they might achieve alone. Shamaazi reserves the right to determine how funds are used to benefit charities, including making direct donations and investing funds to generate further returns for charities. Shamaazi strives to ensure the donations you entrust the MTN Fund with are far reaching and long lasting.  Here’s how we do it:
        </p>
      </section>
      <section className='mt-6'>
        <ol className="list-decimal list-inside">

          <li className="text-2xl text-mtn-blue-800">
            <h2 className="inline my-4">Direct Grants to Worthy Charities</h2>
            <p className='text-base text-mtn-gray mt-4'>We carefully select charities that align with our values and the interests of our MyTenNight Fund community. These charities receive direct grants to help them continue their vital work. Recently, we have supported organisations addressing the most worthy causes and charity supporting the most needy people, such as poverty, food, water, education, orphans and more.</p>
          </li>

          <li className="text-2xl text-mtn-blue-800 mt-6">
            <h2 className="inline my-4">Match Funding</h2>
            <p className='text-base text-mtn-gray mt-4'>We use the fund to incentivise further giving through match funding. This approach encourages donors to contribute by effectively doubling the support for charities that are making a significant difference. By partnering with these impactful organizations, we ensure your generosity inspires others to give as well.</p>
          </li>

          <li className="text-2xl text-mtn-blue-800 mt-6">
            <h2 className="inline my-4">Supporting Sustainable Giving with Advanced Payouts</h2>
            <p className='text-base text-mtn-gray mt-4'>
              The MyTenNight Fund also supports the <a className='underline' href="https://www.evergive.com/" target='_blank' rel="noreferrer">Evergive</a> model by providing advanced payouts to charities on a monthly basis.
              This innovative approach promotes sustainable giving and enables charities to plan for long-term impact with confidence.
            </p>
          </li>

          <li className="text-2xl text-mtn-blue-800 mt-6">
            <h2 className="inline my-4">Discretionary Use for Maximum Impact</h2>
            <p className='text-base text-mtn-gray mt-4'>Beyond these structured methods, we retain the flexibility to use the fund at our discretion, always with the ultimate goal of multiplying the impact of your donations. Our aim is to ensure that every contribution from our donors achieves, on average, three times the impact it might otherwise.</p>
          </li>
        </ol>
      </section>
      <section>
        <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>
        <p className='text-base text-mtn-gray mt-4'>
          We are committed to adhering to the highest ethical and Islamic values in all our decisions regarding which charities to support. This ensures that the funds entrusted to us are used in a manner that aligns with the principles and values that matter most to our community.
        </p>
        <p className='text-base text-mtn-gray mt-4'>
          The examples above illustrate some of the ways we aim to use the MyTenNight Fund. However, the fund is applied in the spirit of maximizing impact and in alignment with our overarching mission of multiplying generosity.
        </p>
        <p>We are dedicated to keeping our processes transparent and accountable, so you can trust that your contributions are being used wisely and effectively.</p>
        <p className='font-normal italic mt-4 text-center'>Thank you for joining us in making a difference. Together, we are transforming generosity into lasting impact.</p>
      </section>

    </Page>
  </WithFooter>
}
