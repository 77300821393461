import education from './icons/education.svg'
import emergencyRelief from './icons/emergency-relief.svg'
import sadaqah from './icons/sadaquah.svg'
import orphanCare from './icons/orphan-care.svg'
import zakat from './icons/zakat.svg'
import waterFood from './icons/water-food.svg'
import whereNeed from './icons/where-need.svg'
import healthHousing from './icons/health-housing.svg'

export const causeIcons: Record<string, string> = {
  'Emergency relief': emergencyRelief,
  'Aid for Palestine': waterFood,
  'Orphan care': orphanCare,
  'Where need is the Greatest': whereNeed,
  'Medical Support': healthHousing,
  Education: education,
  Zakat: zakat,
  Sadaqah: sadaqah
}
