import { withAuth } from '@lib/hooks'
import { callService } from '@lib/services'
import { HttpRequestMethod } from 'http-core-constants'

const UrlList = {
  config: '/api/partner/validate'
}

export const getValidatedPartner = withAuth(async (partner: string, group: string): Promise<string> => {
  return callService(UrlList.config, HttpRequestMethod.POST, {
    partner,
    group
  })
})
