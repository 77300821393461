import { type FC } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { isBreakdownValid, isCampaignOpen, SplitType, useCheckoutPlan } from '@shamaazi/mytennights'
import { Pages } from '~/components/CheckoutProgress'
import { getDefaultDetails, useSharePageDetails } from '~/hooks'
import { isWidget } from '@lib/services'
import { Navigation } from '~/components/navigation'

const RedirectToStart: FC<{ charityId: string, partner?: string }> = ({ charityId, partner }) => {
  let root = Navigation.home
  if (partner) {
    root = Navigation.partnerLanding(partner)
  } else if (isWidget && charityId) {
    root = `/${charityId}`
  }
  return <Navigate to={root} />
}

export const FlowRedirect: FC<{ page: Pages }> = ({ page, children }) => {
  const { plan } = useCheckoutPlan()
  const { partnerName } = useParams<{ partnerName?: string }>()

  if (!isCampaignOpen()) {
    return <RedirectToStart charityId={plan.charity.charity_id} partner={partnerName}/>
  }

  switch (page) {
    case (Pages.Select):
      if (plan.split === SplitType.NotSelected) {
        return <RedirectToStart charityId={plan.charity.charity_id} partner={partnerName}/>
      }
      return <>{children}</>
    case (Pages.Split):
      if (plan.charity.charity_id === '') {
        if (plan.split === SplitType.NotSelected) {
          return <RedirectToStart charityId={plan.charity.charity_id} partner={partnerName}/>
        }
        return <Navigate to="/donation/charity" />
      }
      return <>{children}</>
    case (Pages.Extras):
    case (Pages.Checkout):
      if (!isBreakdownValid(plan)) {
        return <RedirectToStart charityId={plan.charity.charity_id} partner={partnerName}/>
      }
      return <>{children}</>
  }
  return <>{children}</>
}

export const ShareRedirect: FC = ({ children }) => {
  const { plan } = useCheckoutPlan()
  const { sharePageDetails } = useSharePageDetails()
  if (sharePageDetails.email === getDefaultDetails().email) {
    return <RedirectToStart charityId={plan.charity.charity_id} />
  }
  return <>{children}</>
}
