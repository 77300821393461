import { type FC } from 'react'
import { Link } from 'react-router-dom'
import fundraisingWhite from './fundraising-regulator-white.png'
import fundraisingBlack from './fundraising-regulator-black.svg'
import { MTNtracking } from '@shamaazi/mytennights'
import { isWidget } from '@lib/services'

const Footer: FC<{ navigation?: boolean }> = ({ navigation }) => {
  const footerLinks = [
    { url: '/', label: 'Home' },
    { url: 'https://articles.mytennights.com', label: 'Articles' },
    { url: '/about-us', label: 'About Us', handleClick: MTNtracking.aboutUs },
    { url: '/fees', label: 'Fees' },
    { url: '/evergive', label: 'About EverGive' },
    { url: '/faq', label: 'FAQ', handleClick: MTNtracking.faq },
    { url: '/terms-and-conditions', label: 'Terms & Conditions' },
    { url: '/privacy-policy', label: 'Privacy Policy' },
    { url: '/cookie-preferences', label: 'Cookie Preferences' },
    { url: '/contact', label: 'Contact Us' },
    { url: 'https://charity.shamaazi.com', label: 'Charity Registration' },
    { url: '/fund-terms-and-conditions', label: 'MTN Fund' }
  ]

  return <>
  <footer className={`flex flex-col justify-between w-full px-10 py-8 ${navigation ? 'footer-bg' : 'bg-mtn-gray-900'}`}>
    {navigation ? <>
      <div className="flex items-center font-medium lg:items-start">
      <nav className="flex flex-col justify-between flex-grow mr-10 text-sm lg:flex-row">
        {!isWidget && <div className='flex flex-wrap'>
        {footerLinks.map((link) => (
          <Link className="mb-4 mr-4 hover:text-gm-yellow text-white" key={link.url} to={link.url} onClick={link.handleClick}>{link.label}</Link>
        ))}
        </div>}
        <div className="text-xs md:text-sm text-left text-white">
          &copy; Copyright {new Date().getFullYear()} MyTenNights. All rights reserved.
        </div>
      </nav>
    </div>
    <div className="flex flex-col items-start mt-10 sm:flex-row">
      <img className="w-32 mb-10 mr-10 sm:mb-0" src={fundraisingWhite} alt="Fundraising Regulator" />
      <div className="text-xs text-white">
        MyTenNights is a trading name of Shamaazi. <Link className="footer-links" to="/privacy-policy">Privacy Statement</Link>, <Link className="footer-links"
                                                                                                                                        to="/terms-and-conditions">Terms &amp; Conditions</Link>,
        Company
        Registration No: 10622013. <a className="footer-links" href="https://www.fundraisingregulator.org.uk/directory/shamaazi-ltd-ta-my-ten-nights"
                                      rel="noreferrer noopener"
                                      target="_blank"
      >Registered with Fundraising Regulator.</a> Head
        Office: 6-8 Great Eastern Street, London, United Kingdom, EC2A 3NT, United Kingdom. Disclaimer: Shamaazi is not
        affiliated with any external web sites. Shamaazi is not responsible for the content of external internet
        sites and any links from external web sites to this site do not constitute an endorsement of that site
        by Shamaazi.
      </div>
    </div>
    </> : <div className="flex justify-center items-center gap-x-10 w-full">
    <img className="w-32" src={fundraisingBlack} alt="Fundraising Regulator" />
    <div className={`text-xs text-black md:text-sm`}>
          &copy; Copyright {new Date().getFullYear()} MyTenNights. All rights reserved.
    </div>
    </div>}
  </footer>
  </>
}

export const WithFooter: FC<{ navigation?: boolean, backgroundColour?: string }> = ({ navigation = false, backgroundColour = null, children }) => {
  return <div className="min-h-screen flex flex-col">
    <main className={`max-w-full flex-1 ${backgroundColour && `bg-${backgroundColour}`}`}>
      {children}
    </main>
    <Footer navigation={navigation} />
  </div>
}
