import { type FC } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Button, CircleChecked, Page, Spinner } from '@lib/components'
import { AccountTabs, Card, Variant, isCampaignOpen, MTNtracking, loadFridayGiving, getTimeFromTimestamp, getDateFromTimestamp, getFridayGivingAmount, StripeSource } from '@shamaazi/mytennights'

import { Header, WithFooter } from '~/components'

import DonateNowButton from '~/components/DonateNowButton'
import { useAuth } from '@lib/hooks'
import { ScheduleListTestIds } from '@lib/testing'
import fgLogo from '~/img/fgLogo.png'

export const FGScheduleList: FC = () => {
  const { data: fridayGivings, isLoading, isError } = useQuery('friday_giving', async () => loadFridayGiving())
  const { user } = useAuth()

  const myFridaysMtnSchedules = (fridayGivings ?? []).filter(s => !s.stripe_source || s.stripe_source === '' ||
    s.stripe_source === StripeSource.myTenNightsStripeSource)

  const scheduleCards = myFridaysMtnSchedules.map((s, i) => {
    const scheduleNumber = (fridayGivings?.length ?? 0) - i
    return <Card key={s.id} variant="mtn" className="flex flex-col font-medium bg-white" data-test-id={`${ScheduleListTestIds.schedule_}${s.id}`}>
      <section className="p-10 border-b border-mtn-gray-300">
        <h2 className="text-lg text-mtn-blue-800">MyFridays Schedule #{scheduleNumber}</h2>
        <p className="mt-4 text-xs">
          Created at {getTimeFromTimestamp(s.created_time)} on {getDateFromTimestamp(s.created_time)}
        </p>
      </section>
      <section className="p-10 border-b border-mtn-gray-300">
        <div className="grid items-center gap-y-4 gap-x-6 grid-cols-auto-fr">
          <span className="text-sm">Charity</span>
          <span className="text-mtn-blue-800">{s.charity_name}</span>
          <span className="text-sm">Total</span>
          <span className="text-mtn-blue-800">{getFridayGivingAmount(s)}</span>
        </div>
      </section>
      <section className="flex flex-col flex-1 p-10">
        <div className="flex items-center flex-1 mb-6">
          <CircleChecked className="w-8 h-8 mr-4 text-mtn-blue" />
          <span>
            {<>You&apos;re all set up and ready<br /></>}
          </span>
        </div>

        <Link to={`/account/fridayGiving/${s.id}`} className="items-center w-full">
          <Button data-test-id={`${ScheduleListTestIds.scheduleViewEdit_}${s.id}`}
            className="w-full" variant="mtn-inverted" onClick={() => MTNtracking.viewEditFridayGiving(user?.email)}>VIEW / EDIT</Button>
        </Link>
      </section>
    </Card>
  })

  const showRedirectionUI = !isLoading && !isError && (fridayGivings?.length ?? 0) > 0 && myFridaysMtnSchedules.length === 0

  return <WithFooter>
    <Header showProfileLink/>
    <Page skinny className="mt-10">
      <AccountTabs active="friday-giving" variant={Variant.mtn} tracking={MTNtracking} />
      {isLoading && <div className="flex justify-center">
        <Spinner />
      </div>}
      {isError && <p className="font-medium text-mtn-red">
        Sorry, we weren't able to load your donations. <br />Please try again,
        and if the issue persists, <Link className="underline" to="/contact">contact us</Link>.
      </p>}
      {!isLoading && !isError && (fridayGivings?.length ?? 0) === 0 && <>
        <p className="mb-10 font-medium">You have no MyFridays schedules yet. Create one by donating.</p>
        {isCampaignOpen() && <Link to={{ pathname: '/', hash: '#donate' }}>
          <DonateNowButton />
        </Link>}
      </>}
      {showRedirectionUI &&
        <div className="flex flex-col items-center text-center">
          <h1 className="text-3xl font-medium mb-6">Asalamu Alaykum</h1>
          <p className="mb-8 max-w-xl">
            Unfortunately we could not fetch your subscription information. However, we have identified your
            account on our other suite products. Please follow links bellow to log in and manage your
            subscription there.
          </p>
          <div className="inline-block bg-[#131c45] text-white font-medium py-3 px-8 mb-4 rounded">
            <Link to="https://myfridays.com/schedule-list">
              <Button variant='mtn-redirection'>
                <img
                  src={fgLogo}
                  alt="select charity"
                  className="py-1.5 mx-10 w-40"
                />
              </Button>
            </Link>
          </div>
        </div>
      }
      {!isLoading && !isError && (fridayGivings?.length ?? 0) > 0 && <div className="grid justify-center gap-10 mb-20 sm:grid-cols-2">
        {scheduleCards}
      </div>}
    </Page>
  </WithFooter>
}
