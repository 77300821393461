import { type ChangeEvent, useEffect, useState, type FC, useCallback } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { Button, Page, Select, Spinner } from '@lib/components'
import { displayCurrency, GetCurrencyByString } from '@lib/services'
import {
  Card,

  NotFoundError,

  type FridayGivingDTO,
  loadFridayGivingDetails,
  type FridayGivingRun,
  changeFGSCancelState,
  changeFGSRCancelState,
  getDateFromTimestamp,
  getTimeFromTimestamp,
  getFridayGivingAmount,
  fetchFGCheckoutSession,
  getPlatformStripe
} from '@shamaazi/mytennights'
import { Header, WithFooter } from '~/components'
import { FridayBreakdown, getPaymentStatusColor } from './fridayBreakdown'
import { ScheduleTestIds } from '@lib/testing'
import calendar from '~/img/calendar.svg'
import arrDown from '~/img/arrow-down.svg'
import arrUp from '~/img/arrow-up.svg'
import moment from 'moment'
import { useAuth } from '@lib/hooks'

export const displayAmount = (amount: number, currency: string): string => {
  return displayCurrency({ amount, currency: GetCurrencyByString(currency) })
}

const GeneratePastBreakdown: FC<{ currentMonth: number, currentYear: number, schedule: FridayGivingDTO, fridayGivingScheduleID: string }> = ({ currentMonth, currentYear, schedule, fridayGivingScheduleID }) => {
  const [open, setOpen] = useState(false)
  function isValidRun (run: FridayGivingRun): boolean {
    const runDate = moment(run.payment_date)
    return runDate.month() === currentMonth && runDate.year() === currentYear
  }
  const availableRuns = schedule?.runs?.filter(isValidRun)
  return <div className=''>
    <div className='flex gap-x-4 items-center'>
      <p className='text-mtn-blue-800'>
        {moment().set('month', currentMonth).format('MMMM')}
      </p>
      <img onClick={() => setOpen(!open)} src={open ? arrUp : arrDown} className='h-4 w-4 cursor-pointer' alt='more' />
    </div>
    {open && <div className="grid gap-3 mt-10 grid-cols-auto-min-30 md:grid-cols-5 lg:grid-cols-auto-min-30 xl:grid-cols-5">
      {(!availableRuns || availableRuns.length < 1) ? <p> No donation in this period</p> : availableRuns.map((run) => <FridayBreakdown fGScheduleID={fridayGivingScheduleID} schedule={schedule} payment={run} />)}
    </div>}
  </div>
}
export const FGScheduleDetail: FC<{ isRunPage: boolean }> = ({ isRunPage }) => {
  const { fridayGivingScheduleID, paymentDate } = useParams() as { fridayGivingScheduleID: string, paymentDate: string }
  const queryClient = useQueryClient()
  const [currentYear, setCurrentYear] = useState(moment().year())
  const [months, setMonths] = useState<number[]>([])
  const [searchParams] = useSearchParams()
  const { user } = useAuth()

  const updatePaymentMethod = searchParams.get('update') === 'payment_method'

  const availableYears = []
  for (let index = moment().year(); index > 2023; index--) {
    availableYears.push(index)
  }

  const changeYear = (selectedYear: number): void => {
    setCurrentYear(selectedYear)
    const months = []
    let oldestMonth = selectedYear < moment().year() ? 11 : moment().month() - 1
    oldestMonth = oldestMonth < 0 ? 0 : oldestMonth
    for (let index = oldestMonth; index >= 0; index--) {
      months.push(index)
    }
    setMonths(months)
  }

  const getCurrentRun = (): FridayGivingRun | undefined => {
    return schedule?.runs?.filter(run => run.payment_date === paymentDate)[0]
  }

  useEffect(() => {
    changeYear(currentYear)
  }, [currentYear])

  const {
    isLoading,
    isError,
    error,
    data: schedule
  } = useQuery<FridayGivingDTO>(['scheduleDetails', paymentDate, fridayGivingScheduleID], async () => await loadFridayGivingDetails(fridayGivingScheduleID), { retry: 0 })

  const {
    mutate: setFGScheduleState,
    isError: isCancelError,
    isLoading: isCancelLoading
  } = useMutation(async (): Promise<FridayGivingDTO> => await changeFGSCancelState(fridayGivingScheduleID, !(schedule?.cancelled ?? true)), {
    onSuccess: (data: FridayGivingDTO) => {
      // TODO tracking
      queryClient.setQueryData(['scheduleDetails', paymentDate, fridayGivingScheduleID], { ...schedule, cancelled: data.cancelled }) // we only want the updated cancelled status
    }
  })

  const handleClick = useCallback(async (fridayGivingScheduleID: string, schedule: any): Promise<void> => {
    if (!schedule) {
      return
    }
    try {
      if (!user?.email) {
        console.error('User not logged in')
        return
      }
      const sessionId: string = await fetchFGCheckoutSession(fridayGivingScheduleID, schedule, true, user.email)
      const stripe = await getPlatformStripe()
      const result = await stripe?.redirectToCheckout({ sessionId })
      if (result?.error) {
        console.error('Error redirecting to Checkout:', result.error)
      }
    } catch (error) {
      console.error('Error handling payment update:', error)
    }
  }, [user?.email])

  const handleClickWrapper = useCallback((): void => {
    handleClick(fridayGivingScheduleID, schedule).catch((error) => {
      console.error('Error handling payment update:', error)
    })
  }, [handleClick, fridayGivingScheduleID, schedule])

  const {
    mutate: setFGRunState,
    isError: isRunCancelError,
    isLoading: isRunCancelLoading
  } = useMutation(async (): Promise<FridayGivingRun> => await changeFGSRCancelState(fridayGivingScheduleID, paymentDate, !(getCurrentRun()?.skipped ?? true)), {
    onSuccess: (data: FridayGivingRun) => {
      // TODO tracking
      schedule?.runs.map(currentRun => {
        if (currentRun.payment_date === paymentDate) {
          currentRun.skipped = data.skipped
        }
        return currentRun
      })

      queryClient.setQueryData(['scheduleDetails', paymentDate, fridayGivingScheduleID], schedule)
    }
  })

  const upcomingFGRuns = (schedule?.runs?.map((run) => <FridayBreakdown fGScheduleID={fridayGivingScheduleID} schedule={schedule} payment={run} />))
  const pastFGRuns = months.map(month => schedule && <GeneratePastBreakdown currentMonth={month} currentYear={currentYear} schedule={schedule} fridayGivingScheduleID={fridayGivingScheduleID} />)

  const isPaymentDateInFuture = (): boolean => {
    return moment().isBefore(moment(paymentDate))
  }

  useEffect(() => {
    if (updatePaymentMethod) {
      handleClickWrapper()
    }
  }, [updatePaymentMethod, handleClickWrapper])

  const color = getPaymentStatusColor(getCurrentRun()?.payment_status ?? '')
  return <WithFooter>
    <Header />
    {!updatePaymentMethod && <div>
      <div className="mx-6 mt-10">
        {isRunPage
          ? <Link className="underline" to={`/account/fridayGiving/${fridayGivingScheduleID}`}>&lt; Back to MyFridays</Link>
          : <Link className="underline" to="/account/fridayGiving">&lt; Back to My Donations </Link>}
      </div>
      <Page data-test-id={ScheduleTestIds.schedulePage} className="px-10 py-10 font-medium lg:grid lg:gap-x-20 lg:grid-cols-fr-auto">
        <div>
          <h1 className="text-xl text-mtn-blue">MyFridays Schedule</h1>
          {(isLoading) && <div className="flex justify-center mt-20">
            <Spinner />
          </div>}
          {isError && error instanceof NotFoundError && <div className="mt-10">
            <h2>Sorry, we couldn&apos;t find this donation.<br />Please check you are logged in as the correct email address</h2>
          </div>}
          {isError && !(error instanceof NotFoundError) && <div className="mt-10">
            <h2>Sorry, we weren't able to load your schedule. Please try again later and if
              the issue persists, <Link className="underline text-mtn-blue" to="/contact">contact us</Link>
            </h2>
          </div>}
          {!isLoading && !isError && schedule !== undefined && <>
            <p className="my-4 text-mtn-blue-800 text-xs"> {isRunPage ? <> {moment(paymentDate).format('dddd DD MMMM YYYY')} </> : <> Set up on {getDateFromTimestamp(schedule.created_time)} at {getTimeFromTimestamp(schedule.created_time)}</>}  </p>
            {isRunPage && <div className='flex justify-between my-4'>
              <p className={`${color.text} my-2 text-sm text-mtn-blue-800`}>Payment {getCurrentRun()?.skipped ? 'Skipped' : (getCurrentRun()?.payment_status === '' ? 'Pending' : getCurrentRun()?.payment_status)}</p>
              {isPaymentDateInFuture() && <Button loading={isRunCancelLoading} disabled={isRunCancelError} onClick={() => setFGRunState()}
                variant="mtn-tertiary" className={getCurrentRun()?.skipped ? ' text-mtn-gray-100' : 'text-mtn-red'}>{getCurrentRun()?.skipped ? 'Reactivate ' : 'Skip '} Payment</Button>}
              </div>}
            <hr className="text-mtn-gray-300" />
            <div >
              <section className="grid items-center my-10 gap-y-6 gap-x-10 grid-cols-auto-fr">

                <span className="text-sm">Charity</span>
                <span className="text-right text-mtn-blue-800">{schedule.charity_name}</span>
                <span className="text-sm">Weekly Donation</span>
                <span className="text-right">
                  {<span className="text-right text-mtn-blue-800">{displayAmount(schedule.amount, schedule.currency)}</span>
                  }
                </span>
                <span className="text-sm">Tip</span>
                <span className="text-right">
                  {<span className="text-right text-mtn-blue-800">{displayAmount(schedule.tip_amount, schedule.currency)}</span>
                  }
                </span>
                <span className="text-sm">Weekly Transaction Fees</span>
                <div className='text-right'>

                  <span className=" text-mtn-blue-800">{
                    displayAmount(schedule.pay_fees ? schedule.fees + schedule.tip_fees : 0, schedule.currency)}</span>
                </div>
                <hr className="col-span-2 text-mtn-gray-300" />
                <span className="text-sm">Weekly Total</span>
                <span className="font-bold text-right text-mtn-blue-800">{
                  getFridayGivingAmount(schedule)
                }</span>
              </section>
            </div>
            <hr className="col-span-2 my-10 text-mtn-gray-300" />
            {!isRunPage && <div>
              <section>
                <h2 className="text-mtn-blue-800">Upcoming Donations</h2>
                <div className="grid gap-3 mt-2 grid-cols-auto-min-30 md:grid-cols-5 lg:grid-cols-auto-min-30 xl:grid-cols-5">
                  {upcomingFGRuns}
                </div>
              </section>
              <section>
                <h2 className="text-mtn-blue-800  my-4">Past Donations</h2>
                <div className='flex gap-x-2 my-4'>
                  <img alt='calendar' src={calendar} className='w-8 h-8' />
                  <div>
                    <Select
                      variant="mtn"
                      defaultValue={currentYear}
                      aria-label="year"
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => changeYear(Number(e.target.value))}>
                      {
                        availableYears.map(year => <option key={year} value={year}>{`${year}`}</option>)
                      }
                    </Select>
                  </div>
                </div>
                <div className='flex flex-col gap-y-4'>
                  {pastFGRuns}
                </div>
              </section></div>}
          </>}
        </div>
        <div className="flex flex-col">
          {isLoading && <section className="flex items-center justify-center p-7"><Spinner /></section>}
          {!isLoading && !isError && schedule !== undefined && <>
            <Card variant="mtn" className="mt-20 -mx-6 lg:mx-0 lg:w-96 md:mt-0">
              {schedule.gift_aid && <section className="p-7">
                <div className="mt-3">You have <span className=' font-bold'>opt-in</span> for giftaid.</div>
              </section>}
              {!schedule.gift_aid && <section className="p-7">
                <div className="mt-3">You chose <span className=' font-bold'>not to opt-in</span> for giftaid.</div>
              </section>}
            </Card>
            {<Card variant="mtn" className="mt-4 -mx-6 lg:mx-0 lg:w-96">
              {schedule.payment_type !== '' && <section className="p-7">
                <div>Paying with card : {schedule.donor_details.card_brand} ending {schedule.donor_details.last_4_card_digits}</div>
                <div className="mt-3">Expires {schedule.donor_details.card_expiry_month}/{schedule.donor_details.card_expiry_year}</div>
              </section>}
              {!isRunPage && <section className="flex justify-center border-t p-7 border-mtn-gray-300">
                {!schedule.cancelled && <Button loading={isCancelLoading} disabled={isCancelLoading} onClick={() => setFGScheduleState()} variant="mtn-tertiary"
                  className="text-mtn-red">CANCEL SCHEDULE</Button>}
                {schedule.cancelled &&
                  <Button loading={isCancelLoading} disabled={isCancelLoading} onClick={() => setFGScheduleState()} variant="mtn-primary">REACTIVATE</Button>}
                {isCancelError && <p className="text-sm text-mtn-red">Sorry, we were unable to do this</p>}
              </section>}
            </Card>}
            <br />
            <Card variant="mtn" >
                {<section className="flex justify-center border-t p-7 border-mtn-gray-300">
                  {<Button onClick={handleClickWrapper} variant="mtn-tertiary"
                    className="text-mtn-red">UPDATE PAYMENT DETAILS</Button>}
                </section>}
              </Card>
          </>}
        </div>
      </Page>
    </div>}
  </WithFooter>
}
