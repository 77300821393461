import { type FC } from 'react'
import AdditionalContributionCard from '~/components/AdditionalContribution/AdditionalContributionCard'
import MFIcon from '~/img/mf-icon.png'
import EGIcon from '~/img/EG logo.svg'
import { type Currency, displayCurrency } from '@lib/services'
import { type Plan } from '@shamaazi/mytennights/src/hooks'
import { GetNextFridayDates, GetNextThirdOfMonth } from '@shamaazi/mytennights'

interface AdditionalContributionProps {
  isFG?: boolean
  isEG?: boolean
  plan?: Plan
}

const AdditionalContribution: FC<AdditionalContributionProps> = ({ isEG, isFG, plan }) => {
  const formatDate = (date: string): string => {
    return new Date(date).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short'
    })
  }

  const contributions = {
    FG: {
      image: MFIcon,
      amount: displayCurrency({ amount: plan?.fridayGiving as number, currency: plan?.charity.currency as Currency }),
      paymentDate: formatDate(GetNextFridayDates()[0]),
      background: 'linear-gradient(to right, #041630, #052859)',
      imageClasses: 'h-4'
    },
    EG: {
      image: EGIcon,
      amount: displayCurrency({ amount: plan?.wakf as number, currency: plan?.charity.currency as Currency }),
      paymentDate: GetNextThirdOfMonth(),
      background: '#0c373c',
      imageClasses: 'h-4'
    }
  }

  return (
        <div className='mt-7'>
            {(isEG ?? isFG) && (
                <div className="w-full text-center text-mtn-blue-800 text-base md:text-sm font-bold mb-2">
                    Further Donations. <br /> Not Included Today.
                </div>
            )}
            {isFG && <AdditionalContributionCard
                image={contributions.FG.image}
                amount={contributions.FG.amount}
                paymentDate={contributions.FG.paymentDate}
                background={contributions.FG.background}
                imageClasses={contributions.FG.imageClasses}
            />}
            {isEG && <AdditionalContributionCard
                imageClasses={contributions.EG.imageClasses}
                image={contributions.EG.image}
                amount={contributions.EG.amount}
                paymentDate={contributions.EG.paymentDate}
                background={contributions.EG.background}
            />}
        </div>
  )
}

export default AdditionalContribution
