import { InputVariantStyles } from '@lib/components'
import { useExperiment } from '@lib/react-ab-test'
import { MytennightsTestId } from '@lib/testing'
import { ActiveExperiments, EverGivePercentage, EverGiveSharePercentageVariants, getToggleButtonStyle, MessageBox, ScrollHash, useCheckoutPlan, Variant } from '@shamaazi/mytennights'
import { type ReactElement, useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'

export const EverGiveContributionExperiment = ({ egError, setIsEGDisableState, setEgError }: { egError: string, setIsEGDisableState: (value: boolean) => void, setEgError: (value: string) => void }): ReactElement | null => {
  const { plan, setPlan } = useCheckoutPlan()
    type EverGivePercentageType = (typeof EverGivePercentage)[number]
    const [customEvergivePercentage, setCustomEvergivePercentage] = useState<number>(0)
    const [evergiveSharePercentage, setEvergiveSharePercentage] = useState<EverGivePercentageType | undefined>(undefined)
    const { activeVariant: EvergiveVariant } = useExperiment(ActiveExperiments.EverGiveSharePercentage)

    const EgVariantPresent = (): boolean => {
      return EvergiveVariant === EverGiveSharePercentageVariants.variant
    }

    const isEGDisableState = EgVariantPresent() ? !plan.egContributionOptIn : false

    useEffect(() => {
      setIsEGDisableState(isEGDisableState)
    }, [isEGDisableState, setIsEGDisableState])

    const getDetailsToggleButtonStyle = (isToggle: boolean): string => `${getToggleButtonStyle(isToggle, Variant.eg)} w-1/3 md:text-lg text-xs`

    if (!EgVariantPresent()) return null

    return (
        <section className="bg-white border border-mtn-gray-250 p-6 rounded-lg">
            <div id={ScrollHash.egContributionNotSelected} className='text-fg-blue-800 flex flex-row relative justify-between mb-4'>
                <div className='flex flex-col'>
                    <div className='flex flex-row justify-between w-full md:mb-0 relative items-baseline'>
                        <p className="text-mtn-blue-800 text-base font-bold">How much of your donation would you like to last forever?</p>
                        <MessageBox infoIcon tooltipBorder>
                            <div>
                                <p className="font-light">
                                    We’ve partnered with EverGive to ensure your donations create a lasting impact.
                                    Your nightly donation amount will remain the same. This is not an additional payment.
                                    When you contribute, your donations are invested, and the profits are shared with
                                    your chosen charity—provided they are subscribed to the EverGive service.
                                    If they are not, the profits may be allocated to the MyTenNights Fund and distributed
                                    to charities with similar missions. This is a feature in beta, and the ultimate
                                    discretion on whether donations are invested or not will be agreed by your chosen charity.
                                </p>
                                {
                                    <p className="font-light">
                                        We will only invest the percentage of your donation that you opt into, capped at your <b>non-Zakat funds. Zakat funds will not be invested.</b>
                                    </p>}
                                <p className='font-light mt-3'>By opting in, you agree to EverGive
                                    <a href='https://www.evergive.com/company/policies/privacy-policy' target='_blank' className='text-black font-bold' rel="noreferrer"> privacy policy </a>
                                    and <a href='https://www.evergive.com/company/policies/terms-and-conditions' target='_blank' className='text-black font-bold' rel="noreferrer">terms and conditions</a>.</p>
                            </div>
                        </MessageBox>
                    </div>
                    <p className='text-sm text-left text-fg-blue-800 mt-3'>We invest a portion of your nightly donation and give the profits to charity every month forever, so your rewards go on for thousands of years after you die.</p>
                </div>
            </div>
            <div className="flex w-full justify-start items-stretch h-12 gap-x-2 mb-4">
                {EverGivePercentage
                  .map((percentage) => (
                        <button
                            data-test-id={MytennightsTestId.egContibutionButton}
                            key={percentage}
                            onClick={() => {
                              setEvergiveSharePercentage(percentage)
                              setCustomEvergivePercentage(0)
                              setEgError('')
                              setPlan({ ...plan, evergiveSharePercentage: percentage, egContributionOptIn: true })
                            }}
                            className={getDetailsToggleButtonStyle(evergiveSharePercentage === percentage)}
                        >
                            {percentage}%
                        </button>
                  ))}
            </div>
            <div className={`justify-end mt-4`}>
                <div className="relative">
                    <CurrencyInput
                        defaultValue={0}
                        allowNegativeValue={false}
                        value={customEvergivePercentage}
                        id="custom-evergive-percentage"
                        className={`${InputVariantStyles.eg} w-full text-left rounded-xl bg-white pr-10`} // Add padding-right for spacing
                        placeholder={'0.00'}
                        step={0.01}
                        onValueChange={(value) => {
                          if (Number(value) <= 100) {
                            setEvergiveSharePercentage(undefined)
                            setEgError('')
                            setCustomEvergivePercentage(Number(value) || 0)
                            setPlan({ ...plan, evergiveSharePercentage: Number(value), egContributionOptIn: true })
                          }
                        }}
                    />
                    <span className="absolute transform -translate-y-1/2 pointer-events-none right-3 top-1/2 whitespace-nowrap">
                        %
                    </span>
                </div>
            </div>
            {egError && <div className='text-sm text-mtn-orange p-3 mt-3 bg-mtn-pink rounded-lg'>{egError}</div>}
        </section>
    )
}
