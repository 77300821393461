import { type FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { Button, EmailInput } from '@lib/components'
import { subscribe, Products } from '@shamaazi/mytennights'

interface FormData {
  email: string
}

interface SubscribeFormProps {
  isSticky?: boolean
}

export const SubscribeForm: FC<SubscribeFormProps> = ({ isSticky }) => {
  const [submitted, setSubmitted] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>({ defaultValues: { email: '' } })
  const { isLoading, isError, mutate: onSubscribe } = useMutation(async (data: FormData): Promise<void> => {
    await subscribe(data?.email ?? '', [Products.myTenNights])
  }, {
    onSuccess: () => {
      setSubmitted(true)
    }
  })

  return <section className={`bottom-0 w-full transparent ${isSticky ? 'sticky' : ''
    }`}>
    <div className="mx-auto max-w-2xl">
      <div className=" text-center flex justify-center">
        <p className="text-lg font-medium text-white" style={{ maxWidth: '340px' }}>Get a reminder to automate your donations next Ramadan</p>
      </div>
      {submitted && <div className="text-center">
        <p className="text-lg font-medium text-white">Thank you for subscribing!</p>
      </div>}
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      {!submitted && <form className="m-5" onSubmit={handleSubmit((data) => onSubscribe(data))}>
        <div className="flex flex-col xl:px-32 lg:px-7">
          <EmailInput variant="mtn" register={register} errors={errors.email} />
          <p className="mt-1 text-xs text-right text-mtn-red min-h-xs">{errors.email?.message ?? ''}{isError && 'Sorry! Something went wrong subscribing you!'}</p>
        </div>
        <div className="flex justify-center xl:px-32 lg:px-7">

          <Button
            className='bg-transparent cursor-pointer w-full px-7 py-4 rounded-full border-2 justify-center items-center gap-2.5 inline-flex'
            variant="mtn-primary"
            disabled={isLoading}
            loading={isLoading}
          >
            REMIND ME
          </Button>
          <div className="min-h-xs">{/* hacky spacer */}</div>
        </div>
      </form>}
    </div>
  </section>
}
